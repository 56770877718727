import CustomModal from "Components/CustomModal/CustomModal";
import img from "../../../assets/images/main/Rectangle.png";
import CardOffer from "../OffersPackages/CardOffer";
import image from "../../../assets/images/main/image1.png";
import iconCurrancy from "../../../assets/images/icons/dollar-circle.svg";
import { Accordion } from "react-bootstrap";
import "./OfferModal.css";
import ContactModal from "Components/ContactModal/ContactModal";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";

const OfferModal = ({
  showModal,
  handleCloseModal,
  showModalContact,
  handleCloseModalContact,
}) => {
  return (
    <>
      <ContactModal
        showModal={showModalContact}
        handleCloseModal={handleCloseModalContact}
      />

      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="معلومات العرض"
        newClass={"card-details-info modalAccount"}
      >
        {/* ======== START RECENT PROJECT ONE ========== */}
        <div className="recent-project-one card-one-tab ">
          {/* =========== START HEAD CARD =========== */}
          <div className="head-card d-flex justify-content-between align-items-center gap-2">
            <div className="info-right d-flex align-items-center flex-wrap  gap-3">
              <div className="img">
                <img src={img} alt={"image1 card"} />
              </div>
              <div className="info-text">
                <div className="badge-text">إنشاء</div>
                <h2 className="title title-top">مبنى سكني مكون من 10 أدوار </h2>
                <div className="icon-star d-flex  align-items-center  gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12.0009 14.1699C9.87086 14.1699 8.13086 12.4399 8.13086 10.2999C8.13086 8.15994 9.87086 6.43994 12.0009 6.43994C14.1309 6.43994 15.8709 8.16994 15.8709 10.3099C15.8709 12.4499 14.1309 14.1699 12.0009 14.1699ZM12.0009 7.93994C10.7009 7.93994 9.63086 8.99994 9.63086 10.3099C9.63086 11.6199 10.6909 12.6799 12.0009 12.6799C13.3109 12.6799 14.3709 11.6199 14.3709 10.3099C14.3709 8.99994 13.3009 7.93994 12.0009 7.93994Z"
                      fill="#002C63"
                    />
                    <path
                      d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z"
                      fill="#002C63"
                    />
                  </svg>
                  <span className="title">دبي ,المرموم</span>
                </div>
              </div>
            </div>
          </div>
          {/* =========== END HEAD CARD =========== */}
        </div>
        {/* ======== END RECENT PROJECT ONE ========== */}

        {/* ======== START CARD DETAILS INFO ========== */}
        <CardOffer
          numDay={"3"}
          image={image}
          imageCardAlt={"image-card"}
          titleCard={"سينتك للمقاولات والبناء"}
          textCard={"مبنى سكني مكون من 10 أدوار"}
          rateNum={"4.8"}
          textCardTrue={true}
          cardTwoInfo={false}
          iconCurrancy={iconCurrancy}
          iconAlt={"iconCurrancy"}
          priceNum={"10.00000"}
          typePrice={"درهم"}
          textPrice={"السعر"}
          textButtonOne={"قبول"}
          textButtonTwo={"تواصل"}
          buttonTwoActive={false}
          buttonThreeActive={true}
        />
        {/* ======== END CARD DETAILS INFO ========== */}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="1">
            <Accordion.Header>التفاصيل</Accordion.Header>
            <Accordion.Body>
              <h2 className="title">تعليق</h2>
              <p className="text">
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو
                العديد المزيد
              </p>
              <FileUpload
                title={"حمل ملف العرض"}
                text={false}
                showText={false}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default OfferModal;
