import { useTranslation } from "react-i18next";
import icon1 from "../../../assets/LandingPageImages/iconsHeaderCard/01.svg";
import icon2 from "../../../assets/LandingPageImages/iconsHeaderCard/02.svg";
import icon3 from "../../../assets/LandingPageImages/iconsHeaderCard/03.svg";

import "./CardsHeaderBanner.css";
const CardsHeaderBanner = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 0,
      dataAnimation: "fade-left",
      icon: icon1,
      title: t("Experts"),
      text: t(
        "A group of trusted consultants, contractors and suppliers in one platform"
      ),
    },
    {
      id: 1,
      dataAnimation: "fade-up",
      icon: icon2,
      title: t("Get Quotes"),
      text: t(
        "Register now and request a quote according to the work required to be implemented with the click of a button."
      ),
    },
    {
      id: 2,
      dataAnimation: "fade-right",
      icon: icon3,
      title: t("project management"),
      text: t(
        "Apply for managing your project from planning to implementation"
      ),
    },
  ];
  return (
    <>
      {/* ======== START CARD BANNER INFO =========== */}
      <section className="cards-banner-info">
        {/* ========== START ROW ============== */}
        <div className="row g-0">
          {cards.map((card, index) => {
            return (
              <div className="col-12 col-md-4" key={index}>
                {/* ============ START CARD BANNER  ONE ============ */}
                <div className="card-banner-one" data-aos={card.dataAnimation}>
                  {/* ============= START HEADER CARD BANNER ========== */}
                  <div className="header-card-banner d-flex align-items-center gap-3">
                    {/* ======= START ICON CARD  ========= */}
                    <div className="icon-card">
                      <img
                        src={card.icon}
                        alt="icon card"
                        width={"48px"}
                        height={"48px"}
                      />
                    </div>
                    {/* ======= END ICON CARD  ========= */}
                    {/* ======= START TEXT HEAD ========= */}
                    <div className="text-head">
                      <h2 className="title">{card.title} </h2>
                    </div>
                    {/* ======= END TEXT HEAD ========= */}
                  </div>
                  {/* ============= END HEADER CARD BANNER ========== */}
                  {/* ============= START INFO CARD =========== */}
                  <div className="info-card">
                    <p className="text">{card.text}</p>
                  </div>
                  {/* ============= END INFO CARD =========== */}
                </div>
                {/* ============ END CARD BANNER  ONE ============ */}
              </div>
            );
          })}
        </div>
        {/* ========== END ROW ============== */}
      </section>
      {/* ======== END CARD BANNER INFO =========== */}
    </>
  );
};

export default CardsHeaderBanner;
