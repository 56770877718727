import userImage from "../../assets/images/user/userImage.png";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "moment/locale/ar";
import moment from "moment";
import NoResults from "Components/NotFound/noResults";
import i18n from "i18n";
const CenterContent = ({ charts, latest_chats }) => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth.userId);
  const [seriesData, setSeriesData] = useState([]);
  useEffect(() => {
    setSeriesData([
      {
        value: Math.floor((charts?.pending / charts?.all) * 100),
        label: t("pendingProjects"),
      },
      {
        value: Math.floor((charts?.finished / charts?.all) * 100),
        label: t("finishedProjects"),
      },
      {
        value: Math.floor((charts?.approved / charts?.all) * 100),
        label: t("approvedProjects"),
      },
      {
        value: Math.floor((charts?.rejected / charts?.all) * 100),
        label: t("rejected Projects"),
      },
      {
        value: Math.floor((charts?.canceled / charts?.all) * 100),
        label: t("canceled Projects"),
      },
      {
        value: Math.floor((charts?.on_work / charts?.all) * 100),
        label: t("on work Projects"),
      },
    ]);
    SetState({
      options: {
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: seriesData.map((item) => item.label),
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return (
              val +
              " - " +
              (val == t("pendingProjects")
                ? charts?.pending
                : val == t("finishedProjects")
                ? charts?.finished
                : val == t("rejected Projects")
                ? charts?.rejected
                : val == t("approvedProjects")
                ? charts?.approved
                : val == t("canceled Projects")
                ? charts?.canceled
                : val == t("on work Projects")
                ? charts?.on_work
                : 0)
            );
          },
          position: "bottom",
        },
        title: {
          text: t("gradientDonutTitle"),
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: ["#D75B5B", "#6F9BDC", "#5BC8D7"],
      },
    });
  }, [charts, i18n]);

  const [state, SetState] = useState({
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: seriesData.map((item) => item.label),
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          console.log(opts.seriesIndex);
          return (
            val +
            " - " +
            (opts.seriesIndex == 0
              ? charts?.pending
              : val == t("finishedProjects")
              ? charts?.finished
              : val == t("rejected Projects")
              ? charts?.rejected
              : val == t("approvedProjects")
              ? charts?.approved
              : val == t("canceled Projects")
              ? charts?.canceled
              : val == t("on work Projects")
              ? charts?.on_work
              : 0)
          );
        },
        position: "bottom",
      },
      title: {
        text: t("gradientDonutTitle"),
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#D75B5B", "#6F9BDC", "#5BC8D7"],
    },
  });

  return (
    <>
      {/* ======== START ALL CENTER CONTENT INFO REPORT ========== */}
      <div className="all-center-content-report my-4">
        {/* ========= START ROW ========= */}
        <div className="row g-3">
          {/* ========= START COL =========== */}
          <div className="col-12 col-md-6 col-xl-8">
            {/* ========= START TOTAL PROJECTS  =========== */}
            <div className="total-projects card-style">
              {/* ======= START HEADER TOP INFO  ======== */}
              <div className="header-top-info d-flex  justify-content-between flex-wrap  align-items-center  gap-2 ">
                <h2 className="title">{t("totalProjectsTitle")}</h2>
                {/* <div className="form-select-option">
                  <select className="form-select">
                    <option selected>{t("last7DaysOption")}</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div> */}
              </div>
              {/* ======= END HEADER TOP INFO  ======== */}
              {/* ======= START ALL TOTAL PROJECTS CHART  ======== */}
              <div className="all-total-projects-chart">
                {/*======= START CHART ======== */}

                <div className="chart">
                  {charts?.all ? (
                    <ReactApexChart
                      options={state.options}
                      series={seriesData.map((item) => item.value)}
                      type="donut"
                      width={450}
                    />
                  ) : (
                    <NoResults text={"there is no data"} />
                  )}
                </div>

                {/*======= END CHART ======== */}
              </div>
              {/* ======= END ALL TOTAL PROJECTS CHART  ======== */}
            </div>
            {/* ========= END TOTAL PROJECTS  =========== */}
          </div>
          {/* ========= END COL =========== */}
          {/* ========= START COL =========== */}
          <div className="col-12 col-md-6 col-xl-4">
            {/* ========= START FILTER MESSAGES ============= */}
            <div className="all-filter-products filter-message h-100">
              {/* ======= START HEADER TOP INFO  ======== */}
              <div className="header-top-info d-flex  justify-content-between  align-items-center  gap-2 ">
                <h2 className="title">{t("chatTitle")}</h2>
                <Link to="/chat" className="link-all">
                  {t("all")}
                </Link>
              </div>
              {/* ======= END HEADER TOP INFO  ======== */}
              {/* ========= START INFO MESSAGES CONTENT ============ */}
              <div className="info-message-content">
                {/* ========= START MESSAGE ONE ======= */}
                {latest_chats?.length ? (
                  latest_chats?.map((chat, index) => {
                    let user =
                      chat.sender.id == userId ? chat.reciever : chat.sender;
                    console.log(user);
                    return (
                      <Link to={"/chat"} state={user}>
                        <div
                          className={`message-one ${
                            chat.unread_count && "new-message"
                          } `}
                        >
                          <div className="user-info">
                            <img
                              src={user?.image}
                              alt="User"
                              className="user-avatar"
                            />
                            <div className="text-user">
                              <div className="head-message">
                                <span className="name-user">
                                  {i18n.language === "en"
                                    ? user?.company_name_en == ""
                                      ? user?.name
                                      : user?.company_name_en
                                    : user?.company_name_ar == ""
                                    ? user?.name
                                    : user?.company_name_ar}
                                </span>
                                <div className="date-message">
                                  {moment(chat?.created_at)
                                    .locale("ar")
                                    .format("YYYY-MM-DD hh:mm A")}{" "}
                                </div>
                              </div>
                              <p className="text-user-message">
                                {chat?.last_message?.message}
                              </p>
                            </div>
                          </div>
                          <div className="badge-num">{chat.unread_count}</div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div>{t("noChats")}</div>
                )}

                {/* ========= END MESSAGE ONE ======= */}
                {/* ========= START MESSAGE ONE ======= */}

                {/* ========= END MESSAGE ONE ======= */}
              </div>
              {/* ========= END INFO MESSAGES CONTENT ============ */}
            </div>
            {/* ========= END FILTER MESSAGES ============= */}
          </div>
          {/* ========= END COL =========== */}
        </div>
        {/* ========= END ROW ========= */}
      </div>
      {/* ======== END ALL CENTER CONTENT INFO REPORT ========== */}
    </>
  );
};

export default CenterContent;
