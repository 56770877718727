import SectionTitle from "../SectionTitle/SectionTitle";
import imgUser from "../../../assets/LandingPageImages/customer/01.png";
import imgQuote from "../../../assets/LandingPageImages/icons/quote.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import "./CustomerReviews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../axios";
import { useEffect, useState } from "react";

const CustomerReviews = () => {
  const { t, i18n } = useTranslation();
  const cardsReview = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/testimonials", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      });
  }, [i18n.language]);
  return (
    <>
      <section className="customer-reviews padding" id="customer-reviews">
        <div className="container">
          <SectionTitle
            titleSection={t("Customer Reviews")}
            text={t(
              "We always strive to satisfy our customers and seek to provide the best services to assist them"
            )}
            dataAnimationType={"fade-left"}
          />

          <div className="all-customer-reviews">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              dir="rtl"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
              }}
              className="mySwiper"
            >
              {data?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="customer-review-one-01">
                      <div className="icon-quote">
                        <img
                          src={imgQuote}
                          alt="icon quote"
                          width="56px"
                          height="57px"
                          className="image-quote object-fit-cover"
                          loading="lazy"
                        />
                      </div>
                      <div className="text-review">
                        <p className="text">{item?.content}</p>
                      </div>
                      <div className="info-customer-review d-flex align-items-center gap-2">
                        <div className="image-customer">
                          <img
                            src={imgUser}
                            alt="img cusomer"
                            className="object-fit-cover"
                            width="46px"
                            height="46px"
                            loading="lazy"
                          />
                        </div>
                        <div className="info-rate d-flex align-items-center gap-1 flex-column">
                          <h2 className="title-customer-name">{item?.name}</h2>
                          <div className="icons-star-rate d-flex align-items-center gap-1">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-star"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-star"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-star"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-star"
                            />
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-star"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerReviews;
