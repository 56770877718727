import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import * as Yup from "yup";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import iconEdit from "../../../assets/images/icons/edit-2.svg";
import iconUpload from "../../../assets/images/icons/upload.svg";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { useEffect, useState } from "react";
import img from "../../../assets/images/main/Rectangle.png";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";

const FormEditWork = ({
  data,
  refetch,
  setSelectedFiles,
  setSelectedFiles2,
  setSelectedFiles3,
  selectedFiles,
  selectedFiles2,
  selectedFiles3,
}) => {
  // FORM SUBMIT
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  console.log(data?.work_count, "asds");

  const initialValues = {
    about: data?.about,
    // current_work: data?.current_work,
    // work_count: data?.work_count,
    // commercialRegistrationNo: data?.commercial_register,
    // liecense: data?.liecense,
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log("hi from handle");
    setLoading(true);
    console.log(values);
    console.log(selectedFiles3);
    const formData = new FormData();
    formData.append("about", values.about);

    // formData.append("commercial_register", values.commercialRegistrationNo);
    // formData.append("liecense", values.liecense);
    if (selectedFiles[0]?.file) {
      formData.append("company_logo", selectedFiles[0]?.file || null);
    }
    if (selectedFiles2[0]?.file) {
      formData.append("liecense_image", selectedFiles2[0]?.file || null);
    }
    if (selectedFiles3[0]?.file) {
      formData.append("company_profile", selectedFiles3[0]?.file || null);
    }
    axiosInstance
      .post("/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success(t("Data Updated Successfully"));
        refetch();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("Failed to update data"));
      });
    resetForm({ values: "" });
  };
  useEffect(() => {
    console.log(data, isActiveUpload, data?.company_logo);
  }, []);
  const validationSchema = Yup.object().shape({
    about: Yup.string()
      .min(10, t("MinContentLength"))
      .max(4000, t("MaxContentLength"))
      .required(t("AboutRequired")),

    // current_work: Yup.string().required(t("CurrentWorkRequired")),
    // work_count: Yup.string().required(t("WorkCountRequired")),

    // commercialRegistrationNo: Yup.string().required(
    //   t("CommercialRegistrationRequired")
    // ),
    // liecense: Yup.string(),
  });
  const [isActiveUpload, setIsActiveUpload] = useState(
    data?.company_logo ? false : true
  );
  const [isActiveUpload2, setIsActiveUpload2] = useState(
    data?.liecense_image ? false : true
  );
  const [isActiveUpload3, setIsActiveUpload3] = useState(
    data?.company_profile ? false : true
  );

  return (
    <>
      {/* ========= START FORM INFO  ========== */}
      <div className="form-info">
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row g-3">
            {/* ========= START COL ======== */}
            <div className="col-12 col-md-12">
              <TextAreaInput
                label=""
                name="about"
                type="text"
                placeholder={t("AboutPlaceholder")}
                success
              />
            </div>
            {/* ========= END COL ======== */}

            {/* ========= START COL ======== */}
            {/* ========= Start Delete Inputs Fileds ======== */}

            {/* ========= End Delete Inputs Fileds ======== */}
            {/* ========= END COL ======== */}
            {/* ========= START COL ======== */}
            <div className="col-12 col-md-12">
              {isActiveUpload === false ? (
                <div className="main-card-edit">
                  <h2 className="title">{t("CompanyImageTitle")}</h2>
                  <div className="info-bottom-edit d-flex align-items-center gap-2 justify-content-between">
                    <div className="file-uploaded-01 d-flex align-items-center gap-2">
                      <img
                        src={data?.company_logo}
                        alt="icon upload"
                        style={{
                          width: "180px",
                          height: "90px",
                          borderRadius: "10px",
                        }}
                      />
                      <p className="text-up"></p>
                    </div>
                    <div
                      onClick={() => {
                        setIsActiveUpload(true);
                      }}
                      className="edit-icon d-flex align-items-center gap-2"
                    >
                      <img src={iconEdit} alt="icon edit" />
                      <p className="text-up">{t("Edit")}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <FileUpload
                  title={t("AttachCompanyFileTitle")}
                  text={t("PdfDoc")}
                  showText={false}
                  SelectedData={setSelectedFiles}
                  value={selectedFiles}
                />
              )}
            </div>
            {/* ========= END COL ======== */}
            {/* ========= START COL ======== */}
            <div className="col-12 col-md-12">
              {isActiveUpload2 === false ? (
                <div className="main-card-edit">
                  <h2 className="title">{t("LicenseImageTitle")}</h2>
                  <div className="info-bottom-edit d-flex align-items-center gap-2 justify-content-between">
                    <div className="file-uploaded-01 d-flex align-items-center gap-2">
                      <img
                        className="img-A"
                        src={data?.liecense_image}
                        alt="img"
                        style={{
                          width: "180px",
                          height: "90px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setIsActiveUpload2(true);
                      }}
                      className="edit-icon d-flex align-items-center gap-2"
                    >
                      <img src={iconEdit} alt="icon edit" />
                      <p className="text-up">{t("Edit")}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <FileUpload
                  title={t("LicenseImageTitle")}
                  text={t("PngJpg")}
                  showText={false}
                  SelectedData={setSelectedFiles2}
                  value={selectedFiles2}
                />
              )}
            </div>
            <div className="col-12 col-md-12">
              {isActiveUpload3 === false ? (
                <div className="main-card-edit">
                  <h2 className="title">{t("CompanyProfile")}</h2>
                  <div className="info-bottom-edit d-flex align-items-center gap-2 justify-content-between">
                    <div className="file-uploaded-01 d-flex align-items-center gap-2">
                      <img
                        className="img-A"
                        src={data?.company_profile}
                        alt="img"
                        style={{
                          width: "180px",
                          height: "90px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setIsActiveUpload3(true);
                      }}
                      className="edit-icon d-flex align-items-center gap-2"
                    >
                      <img src={iconEdit} alt="icon edit" />
                      <p className="text-up">{t("Edit")}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <FileUpload
                  title={t("CompanyProfile")}
                  text={t("PngJpg")}
                  showText={false}
                  SelectedData={setSelectedFiles3}
                  value={selectedFiles3}
                />
              )}
            </div>
            {/* ========= END COL ======== */}
          </div>
          <button className="btn-main w-100" type="submit">
            {loading ? <span className="loader"></span> : t("Save")}
          </button>
        </FormField>
      </div>
      {/* ========= END FORM INFO ========== */}
    </>
  );
};

export default FormEditWork;

// the inputs filed (old)
// <div className="col-12 col-md-6">
// <InputFiled
//   label={t("CurrentWorkLabel")}
//   name="current_work"
//   type="number"
//   placeholder=""
//   success
//   iconInput={""}
// />
// </div>
// {/* ========= END COL ======== */}
// {/* ========= START COL ======== */}
// <div className="col-12 col-md-6">
// <InputFiled
//   label={t("EmployeeCountLabel")}
//   name="work_count"
//   type="number"
//   placeholder=""
//   success
//   iconInput={""}
// />
// </div>
// {/* ========= END COL ======== */}
// {/* ========= START COL ======== */}
// <div className="col-12 col-md-12">
// <InputFiled
//   label={t("CommercialRegistrationLabel")}
//   name="commercialRegistrationNo"
//   type="number"
//   placeholder=""
//   success
//   iconInput={""}
// />
// </div>
// <div className="col-12 col-md-12">
// <InputFiled
//   label={t("liecense")}
//   name="liecense"
//   type="text"
//   placeholder=""
//   success
//   iconInput={""}
// />
// </div>
