import HeaderTopHome from "Pages/Home/HeaderTop/HeaderTopHome";
import BannerAccount from "./BannerAccount";
import "./MyAccount.css";
import EditInfoAccount from "./EditInfoAccount";
import iconEdit from "../../assets/images/icons/edit-2.svg";
import userIcon from "../../assets/images/icons/user.svg";
import portfolio from "../../assets/images/portfolio.png";
import briefcaseIcon from "../../assets/images/icons/briefcase.svg";
import RateCardUser from "Components/RateCardUser/RateCardUser";
import PaginationPage from "Components/Pagination/Pagination";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProtfolioEditModal from "./ModalAccount/ProtoflioEdit";

const MyAccount = () => {
  const galleryProducts = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
  const { t, i18n } = useTranslation();
  const [data, setData] = useState();
  const type = useSelector((state) => state.auth.type);
  const [showModalPR, setShowModalPR] = useState(false);

  useEffect(() => {
    axiosInstance
      .get("/profile", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data, "sdasda");
        setData(res.data.data);
      });
  }, []);
  const refetch = () => {
    axiosInstance
      .get("/profile", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      });
  };
  return (
    <>
      <HelmetInfo titlePage={t("accountTitle")} />
      <ProtfolioEditModal
        showModalEditWork={showModalPR}
        handleCloseModalEditWork={() => setShowModalPR(false)}
        refetch={refetch}
        data={data}
      />
      <main>
        {/* ============ START MY ACCOUNT ========== */}
        <div className="my-account">
          {/* ========== START BANNER ACCOUNT =========== */}
          <BannerAccount refetch={refetch} Data={data} />
          {/* ========== END BANNER ACCOUNT =========== */}
          {/* ======== START HEADER ACCOUNT ======== */}
          {type !== "customer" && (
            <HeaderTopHome
              refetch={refetch}
              editPassword={true}
              refetch={refetch}
              data={data}
            />
          )}
          {/* ======== END HEADER ACCOUNT ======== */}
          {/* ======== START EDIT ACCOUNT ======= */}
          <div className="all-edit-account my-3">
            <div className="row g-3">
              <div className="col-12 col-md-4">
                <EditInfoAccount
                  userIcon={userIcon}
                  title={t("editAccountInfo")}
                  iconEdit={iconEdit}
                  isAciveEditInfoAccountButton={true}
                  isAciveEditInfoWorkButton={false}
                  data={data}
                  refetch={refetch}
                />
              </div>
              {type !== "customer" && (
                <div className="col-12 col-md-4">
                  <EditInfoAccount
                    userIcon={briefcaseIcon}
                    title={t("editWorkInfo")}
                    iconEdit={iconEdit}
                    isAciveEditInfoAccountButton={false}
                    isAciveEditInfoWorkButton={true}
                    data={data}
                    refetch={refetch}
                  />
                </div>
              )}

              {type !== "customer" && (
                <div className="col-12 col-md-4">
                  <div
                    className="edit-info"
                    onClick={() => setShowModalPR(true)}
                  >
                    <div className="icon-info">
                      <img
                        width={61}
                        height={61}
                        src={portfolio}
                        alt="icon edit"
                      />
                    </div>
                    <div className="info-text">
                      <h2 className="title">{t("Portfolio")}</h2>

                      <div className="icon-edit">
                        <img src={iconEdit} alt="icon edit" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {type != "customer" && data?.rates.length > 0 && (
            <div className="rates-users">
              <div className="header-rates">
                <h2 className="title">{t("customerReviews")}</h2>
              </div>

              <div className="all-rates-users">
                <div className="row g-3">
                  {data?.rates.map((item, index) => {
                    return (
                      <div className="col-12" key={index}>
                        <RateCardUser item={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {/* ======== END RATES USER ======= */}
        </div>
        {/* ============ END MY ACCOUNT ========== */}
      </main>
    </>
  );
};

export default MyAccount;
