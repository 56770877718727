import LoginForm from "Components/Forms/Login/LoginForm";
import LoginStepOne from "Components/Forms/LoginStepOne/LoginStepOne";
import Register from "Components/Forms/Register/Register";
import TermsConditions from "Pages/TermsConditions/TermsConditions";
import Otp from "Pages/Otp/Otp";
import SuccessData from "Components/Success/SuccessData";
import ChooseSpecialty from "Components/Forms/ChooseSpecialty/ChooseSpecialty";
import BasicAccountInformation from "Components/Forms/BasicAccountInformation/BasicAccountInformation";
import SuccessData2 from "Components/Success/SuccessData2";
import WorkInformation from "Pages/WorkInformation/WorkInformation";
import ContactInformation from "Pages/WorkInformation/ContactInformation";
import MapWithSearch from "Pages/WorkInformation/LocateCompany";
import FinalCompleteData from "Pages/WorkInformation/FinalCompleteData";
import Offers from "Pages/Offers/Offers";
import ProjectManagementRequest from "Pages/ProjectManagementRequest/ProjectManagementRequest";
import AddNewProject from "Pages/AddNewProject/AddNewProject";
import AllProjects from "Pages/AllProjects/AllProjects";
import AllExperts from "Pages/AllExperts/AllExperts";
import ProductDetailsPage from "Pages/ProductDetailsPage/ProductDetailsPage";
import TheExpert from "Pages/AllExperts/TheExpert";
import MyProjects from "Pages/MyProjects/MyProjects";
import ProjectManagementRequestsDetails from "Pages/MyProjects/ProjectManagementRequestsDetails";
import MyProjectDetails from "Pages/MyProjects/MyProjectDetails";
import ProjectOneDetails from "Pages/MyProjects/ProjectOneDetails";
import Chat from "Pages/Chat/Chat";
import PackagesCards from "Pages/Packages/Packages";
import Subscriptions from "Pages/Subscriptions/Subscriptions";
import Archives from "Pages/Archives/Archives";
import MyAccount from "Pages/MyAccount/MyAccount";
import Reports from "Pages/Reports/Reports";
import Layout from "Components/Layout/Layout";
import Home from "Pages/Home/Home";
import NotFound from "Pages/Notfound/NotFound";
import { Navigate, createBrowserRouter } from "react-router-dom";
import LandingPage from "Pages/LandingPage/LandingPage";
import WorkProjects from "Pages/MyProjects/WorkProjects";
import ForgetPassword from "Components/Forms/Login/ForgetPassword";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import NewPassword from "Components/Forms/Login/NewPassword";
import Profits from "Pages/Wallet/Wallet";
import SharingProjectDetails from "Components/SharingProject/SharingProjectDetails";

// Define a function that checks if the user is authenticated

// Apply the authentication guard to protected routes
// For example, if you want to protect the MyProjects route:
// Replace <MyProjects /> with AuthGuard(<MyProjects />)
// This will ensure that MyProjects is only accessible to authenticated users

const AuthGuard = ({ children }) => {
  const token = Cookies.get("token");
  const tokenRedux = useSelector((state) => state.auth.token);
  if (token && tokenRedux) {
    return children;
  } else {
    return <Navigate to="/loginForm" />;
  }
};

let routers = createBrowserRouter([
  { index: true, element: <LandingPage /> },
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "home",
        element: (
          <AuthGuard>
            <Home />
          </AuthGuard>
        ),
      },
      {
        path: "allProjects",
        element: (
          <AuthGuard>
            <AllProjects />
          </AuthGuard>
        ),
      },
      {
        path: "productDetailsPage/:id",
        element: (
          <AuthGuard>
            <ProductDetailsPage />
          </AuthGuard>
        ),
      },
      {
        path: "allExperts",
        element: (
          <AuthGuard>
            <AllExperts />
          </AuthGuard>
        ),
      },
      {
        path: "theExpert/:idExpert",
        element: (
          <AuthGuard>
            <TheExpert />
          </AuthGuard>
        ),
      },
      // new route of sharing
      // {
      //   path: "sharingProductDetails",
      //   element: (
      //     // <AuthGuard>
      //     //   <TheExpert />
      //     // </AuthGuard>
      //     <SharingProjectDetails />
      //   ),
      // },
      {
        path: "myProjects",
        element: (
          <AuthGuard>
            <MyProjects />
          </AuthGuard>
        ),
        children: [
          {
            index: "true",
            element: (
              <AuthGuard>
                <MyProjectDetails />
              </AuthGuard>
            ),
          },
          {
            path: "projectManagementRequestsDetails",
            element: (
              <AuthGuard>
                <ProjectManagementRequestsDetails />
              </AuthGuard>
            ),
          },
          {
            path: "projectOneDetails/:id",
            element: (
              <AuthGuard>
                <ProjectOneDetails />
              </AuthGuard>
            ),
          },
          {
            path: "workProject",
            element: (
              <AuthGuard>
                <WorkProjects />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: "offers",
        element: (
          <AuthGuard>
            <Offers />
          </AuthGuard>
        ),
      },
      {
        path: "projectManagementRequest",
        element: (
          <AuthGuard>
            <ProjectManagementRequest />
          </AuthGuard>
        ),
      },
      {
        path: "chat",
        element: (
          <AuthGuard>
            <Chat />
          </AuthGuard>
        ),
      },
      {
        path: "packagesCards",
        element: (
          <AuthGuard>
            <PackagesCards />
          </AuthGuard>
        ),
      },
      {
        path: "subscriptions",
        element: (
          <AuthGuard>
            <Subscriptions />
          </AuthGuard>
        ),
      },
      {
        path: "archives",
        element: (
          <AuthGuard>
            <Archives />
          </AuthGuard>
        ),
      },
      {
        path: "myAccount",
        element: (
          <AuthGuard>
            <MyAccount />
          </AuthGuard>
        ),
      },
      {
        path: "reports",
        element: (
          <AuthGuard>
            <Reports />
          </AuthGuard>
        ),
      },
      {
        path: "addNewProject",
        element: (
          <AuthGuard>
            <AddNewProject />
          </AuthGuard>
        ),
      },
      {
        path: "*",
        element: (
          <AuthGuard>
            <NotFound />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet",
        element: (
          <AuthGuard>
            <Profits />
          </AuthGuard>
        ),
      },
    ],
  },
  { path: "loginForm", element: <LoginForm /> },
  { path: "forgetPassword", element: <ForgetPassword /> },
  { path: "NewPassword", element: <NewPassword /> },

  { path: "register", element: <Register /> },
  { path: "termsConditions", element: <TermsConditions /> },
  {
    path: "otp",
    element: (
      <Otp
        openPopupSuccess={undefined}
        isAcriveButtonModal={undefined}
        showModalConfirmNumber={undefined}
        handleCloseModalConfirmNumber={undefined}
      />
    ),
  },
  { path: "successData", element: <SuccessData showButton={""} /> },
  { path: "successData2", element: <SuccessData2 /> },
  { path: "chooseSpecialty", element: <ChooseSpecialty /> },
  { path: "basicAccountInformation", element: <BasicAccountInformation /> },
  { path: "workInformation", element: <WorkInformation /> },
  { path: "contactInformation", element: <ContactInformation /> },
  { path: "locateCompany", element: <MapWithSearch /> },
  { path: "finalCompleteData", element: <FinalCompleteData /> },
  { path: "loginStepOne", element: <LoginStepOne /> },
  { path: "project/:id", element: <SharingProjectDetails /> },
]);

export default routers;
