import HeaderPages from "Components/HeaderPages/HeaderPages";
import { Tab, Tabs } from "react-bootstrap";
import "./Offers.css";
import CardOffer from "Pages/Home/OffersPackages/CardOffer";
import image from "../../assets/images/main/image1.png";
import iconCurrancy from "../../assets/images/icons/dollar-circle.svg";
import CardInfoDetails from "Pages/Home/CardsInfo/CardInfoDetails";
import image_2 from "../../assets/images/icons/bars.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";

const Offers = () => {
  const [Offer, SetOffers] = useState([]);
  const [OfferEnded, SetOffersEnded] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    axiosInstance
      .get("/offer", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((response) => {
        SetOffers(response.data.otheroffers);
      });
  }, [i18n.language]);
  return (
    <>
      <HelmetInfo titlePage={"العروض"} />
      <div className="all-offer-page">
        <HeaderPages
          titlePage={t("offers")}
          routePage={false}
          mainTitlePage={false}
          showMainTitlePage={false}
        />
        {/* ========== START INFO OFFER PAGE ======== */}
        <div className="info-offer-page">
          {/*======== START ROW ========= */}
          <div className="row g-3">
            {/* ========= START COL ========== */}
            <div className="col-12   col-lg-12 col-xl-12">
              {/* ========= START ALL TABS OFFERS ========= */}
              <div className="all-taps-offer">
                <Tabs
                  defaultActiveKey="now"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="now" title={t("Current")}>
                    {Offer?.current?.length ? (
                      Offer?.current?.map((item, index) => {
                        console.log(item);
                        return (
                          <CardOffer
                            numDay={"3"}
                            key={index}
                            expertId={item.provider.id}
                            image={item.provider.image}
                            imageCardAlt={"image-card"}
                            titleCard={
                              i18n.language === "en"
                                ? item.provider.company_name_en
                                : item.provider.company_name_ar
                            }
                            textCard={item.project.title}
                            textCardTrue={true}
                            cardTwoInfo={false}
                            iconCurrancy={iconCurrancy}
                            iconAlt={"iconCurrancy"}
                            priceNum={item.price}
                            typePrice={t("AED")}
                            textPrice={t("price")}
                            textButtonOne={t("Accept")}
                            textButtonTwo={t("offerDetails")}
                            buttonTwoActive={true}
                            buttonThreeActive={undefined}
                            rateNum={undefined}
                            offerPages={true}
                            projectId={item.project.id}
                          />
                        );
                      })
                    ) : (
                      <NoResults />
                    )}
                  </Tab>
                  <Tab eventKey="ending" title={t("done")}>
                    {Offer?.finished?.length ? (
                      Offer?.finished?.map((item, index) => {
                        return (
                          <CardOffer
                            key={index}
                            expertId={item.provider.id}
                            numDay={"3"}
                            image={item.provider.image}
                            imageCardAlt={"image-card"}
                            titleCard={item.provider.name}
                            textCard={item.project.title}
                            textCardTrue={true}
                            cardTwoInfo={false}
                            iconCurrancy={iconCurrancy}
                            iconAlt={"iconCurrancy"}
                            priceNum={item.price}
                            typePrice={t("AED")}
                            textPrice={t("price")}
                            textButtonOne={"قبول"}
                            textButtonTwo={"تفاصيل العرض"}
                            buttonTwoActive={true}
                            buttonThreeActive={undefined}
                            rateNum={undefined}
                            offerPages={true}
                            projectId={item.project.id}
                          />
                        );
                      })
                    ) : (
                      <NoResults />
                    )}
                  </Tab>
                </Tabs>
              </div>
              {/* ========= END ALL TABS OFFERS ========= */}
            </div>
            {/* ========= END COL ========== */}
            {/* ========= START COL ========== */}
            {/* <div className="col-12  col-lg-5  col-xl-4">

              <div className="quick-statistics card-style">
                <div className="title-quick">
                  <h2 className="title">إحصائيات سريعة</h2>
                </div>
       
                <div className="info-quick-statistics cards-info">
                  <div className="row g-3">
                    <div className="col-12">
                      <CardInfoDetails
                        image_1={image_2}
                        newClass={"card-one-1"}
                        titleCard={"عروض جديدة"}
                        NumCounter={"20"}
                        iconCounter={"+"}
                        NumK={undefined}
                        badgeInfo={undefined}
                        numBadge={undefined}
                      />
                    </div>
                    <div className="col-12">
                      <CardInfoDetails
                        image_1={image_2}
                        newClass={"card-one-1"}
                        titleCard={"عروض منتهية"}
                        NumCounter={"20"}
                        iconCounter={"+"}
                        NumK={undefined}
                        badgeInfo={undefined}
                        numBadge={undefined}
                      />
                    </div>
                  </div>
                </div>

              </div>
        
            </div> */}
            {/* ========= END COL ========== */}
          </div>
          {/*======== END ROW ========= */}
        </div>
        {/* ========== END INFO OFFER PAGE ======== */}
      </div>
    </>
  );
};

export default Offers;
