import PaginationPage from "Components/Pagination/Pagination";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import CardProductDetails from "Pages/ProductDetailsPage/CardProductDetails";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import CardSkelaton from "Components/Skelaton/CardSkelaton";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { useLottie } from "lottie-react";
import NoData from "../../assets/NoDataFound.json";
import NoResults from "Components/NotFound/noResults";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const MyProjectDetails = () => {
  const projectsCard = [{ id: 0 }, { id: 2 }, { id: 3 }];
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState([]);
  const [badgeView, setBadgeView] = useState(3);
  const [openProjects, setOpenProjects] = useState([]);
  const [closeProjects, setCloseProjects] = useState([]);
  const type = useSelector((state) => state.auth.type);

  const options = {
    animationData: NoData,
    loop: true,
    style: { width: "400px", height: "400px" },
  };
  const { View } = useLottie(options);
  useEffect(() => {
    axiosInstance
      .get("/project", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.myprojects);
        setAllProjects(res.data.myprojects.all);
        setOpenProjects(res.data.myprojects.current);
        setCloseProjects(res.data.myprojects.finished);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    axiosInstance
      .get("/offer", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.otheroffers);

        setLoading(false);
      });
  });
  return (
    <>
      <div className="my-projects card-style margin-top-content position-relative">
        <div className="button-order d-flex gap-2">
          {type !== "customer" && (
            <Link to={"workProject"} className="btn-main button-home">
              {t("Projects I'm working on")}
            </Link>
          )}
          <Link
            to={"projectManagementRequestsDetails"}
            className="btn-main button-home"
          >
            {t("Project management requests")}
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        </div>
        {/* ======= START TABS CONTENT ========= */}
        <div className="tabs-content">
          <Tabs
            defaultActiveKey="profile"
            onSelect={(k) => setBadgeView(3)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="profile" title={t("all")}>
              {/* ========= START ALL PROJECTS CARD ========== */}
              <div className="all-projects-card">
                {/* ======= START MAIN PROJECTS CARD ======== */}
                <div className="main-projects-card">
                  {loading ? (
                    [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                  ) : allProjects.length ? (
                    allProjects
                      .slice(badgeView - 3, badgeView)
                      .map((item, index) => {
                        return (
                          <div className="card-details-pro" key={index}>
                            <CardProductDetails
                              projectId={item.id}
                              img={item?.images[0]}
                              altImg={"img Card"}
                              badgeText={item?.category}
                              titleCard={item?.title}
                              titleAddress={`${item.city} , ${item.region}`}
                              iconDollar={iconDollar}
                              priceCard={item.price}
                              typePrice={t("currency")}
                              textPrice={t("expectedValue")}
                              iconCalendar={iconCalendar}
                              dateCard={item.end_date}
                              textInfoDate={t("expectedDeliveryDate")}
                              numWatch={item.views}
                              textWatch={t("views")}
                              numOffer={item.offers_count}
                              status={item.status}
                              textOffer={t("offers")}
                              numDay={`متبقي ${"a"} أيام`}
                              isActiveMainButtons={false}
                              isActiveButtonUpdateAndStop={false}
                              isActiveFinalCard={true}
                              dateCard2={"01/01/2025"}
                              textInfoDate2={t("Project start date")}
                              dateCard3={"01/01/2025"}
                              textInfoDate3={t("Date of acceptance of offers")}
                              isActiveDate={false}
                              isActiveOffersWatch={false}
                              status={item.status}
                              routeCard={"projectOneDetails"}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <NoResults text={"NoResults"} />
                  )}
                </div>
                {/* ======= END MAIN PROJECTS CARD ======== */}
                <PaginationPage
                  setBadgeView={setBadgeView}
                  itmeCount={allProjects.length / 3}
                  itemperPage={3}
                />
              </div>
              {/* ========= END ALL PROJECTS CARD ========== */}
            </Tab>
            <Tab eventKey="profile-2" title={t("Opend")}>
              <div className="all-projects-card">
                {/* ======= START MAIN PROJECTS CARD ======== */}
                <div className="main-projects-card">
                  {loading ? (
                    [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                  ) : openProjects.length ? (
                    openProjects
                      .slice(badgeView - 3, badgeView)
                      .map((item, index) => {
                        return (
                          <div className="card-details-pro" key={index}>
                            <CardProductDetails
                              projectId={item.id}
                              img={item?.images[0]}
                              altImg={"img Card"}
                              badgeText={item?.category}
                              titleCard={item?.title}
                              titleAddress={`${item.city} , ${item.region}`}
                              iconDollar={iconDollar}
                              priceCard={item.price}
                              typePrice={t("currency")}
                              textPrice={t("expectedValue")}
                              iconCalendar={iconCalendar}
                              dateCard={item.end_date}
                              textInfoDate={t("expectedDeliveryDate")}
                              numWatch={item.views}
                              textWatch={t("views")}
                              numOffer={item.offers_count}
                              textOffer={t("offers")}
                              numDay={`متبقي ${"a"} أيام`}
                              isActiveMainButtons={false}
                              isActiveButtonUpdateAndStop={false}
                              isActiveFinalCard={true}
                              dateCard2={"01/01/2025"}
                              textInfoDate2={t("Project start date")}
                              dateCard3={"01/01/2025"}
                              textInfoDate3={t("Date of acceptance of offers")}
                              isActiveDate={false}
                              isActiveOffersWatch={false}
                              routeCard={"projectOneDetails"}
                              status={item.status}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <NoResults text={"NoResults"} />
                  )}
                </div>
                {/* ======= END MAIN PROJECTS CARD ======== */}
                <PaginationPage
                  setBadgeView={setBadgeView}
                  itmeCount={openProjects.length / 3}
                  itemperPage={3}
                />
              </div>
            </Tab>
            <Tab eventKey="profile-3" title={t("Closed")}>
              {/* ======= START MAIN PROJECTS CARD ======== */}
              <div className="main-projects-card">
                {loading ? (
                  [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                ) : closeProjects.length ? (
                  closeProjects
                    .slice(badgeView - 3, badgeView)
                    .map((item, index) => {
                      return (
                        <div className="card-details-pro" key={index}>
                          <CardProductDetails
                            projectId={item.id}
                            img={item?.images[0]}
                            altImg={"img Card"}
                            badgeText={item?.category}
                            titleCard={item?.title}
                            titleAddress={`${item.city} , ${item.region}`}
                            iconDollar={iconDollar}
                            priceCard={item.price}
                            typePrice={t("currency")}
                            textPrice={t("expectedValue")}
                            iconCalendar={iconCalendar}
                            dateCard={item.end_date}
                            textInfoDate={t("expectedDeliveryDate")}
                            numWatch={item.views}
                            textWatch={t("views")}
                            numOffer={item.offers_count}
                            textOffer={t("offers")}
                            numDay={`متبقي ${"a"} أيام`}
                            isActiveMainButtons={false}
                            isActiveButtonUpdateAndStop={false}
                            isActiveFinalCard={true}
                            dateCard2={"01/01/2025"}
                            textInfoDate2={t("Project start date")}
                            dateCard3={"01/01/2025"}
                            textInfoDate3={t("Date of acceptance of offers")}
                            isActiveDate={false}
                            isActiveOffersWatch={false}
                            routeCard={"projectOneDetails"}
                            status={item.status}
                          />
                        </div>
                      );
                    })
                ) : (
                  <NoResults text={"NoResults"} />
                )}
              </div>
              {/* ======= END MAIN PROJECTS CARD ======== */}
              <PaginationPage
                setBadgeView={setBadgeView}
                itmeCount={closeProjects.length / 3}
                itemperPage={3}
              />
            </Tab>
            {/* <Tab eventKey="profile-4" title="ألعروض">
         
              <div className="main-projects-card">
                {loading ? (
                  [1, 2, 3].map((item) => <CardSkelaton />)
                ) : closeProjects.length ? (
                  closeProjects.slice(badgeView - 3, badgeView).map((item) => {
                    return (
                      <div className="card-details-pro" key={item.id}>
                        <CardProductDetails
                          projectId={item.id}
                                  img={item?.images[0]}
                          altImg={"img Card"}
                          badgeText={item?.category}
                          titleCard={item?.title}
                          titleAddress={`${item.city} , ${item.region}`}
                          iconDollar={iconDollar}
                          priceCard={item.price}
                          typePrice={t("currency")}
                          textPrice={t("expectedValue")}
                          iconCalendar={iconCalendar}
                          dateCard={item.end_date}
                          textInfoDate={t("expectedDeliveryDate")}
                          numWatch={item.views}
                          textWatch={t("views")}
                          numOffer={item.offers_count}
                          textOffer={t("offers")}
                          numDay={`متبقي ${"a"} أيام`}
                          isActiveMainButtons={false}
                          isActiveButtonUpdateAndStop={true}
                          isActiveFinalCard={true}
                          dateCard2={"01/01/2025"}
                          textInfoDate2={t("Project start date")}
                          dateCard3={"01/01/2025"}
                          textInfoDate3={t("Date of acceptance of offers")}
                          isActiveDate={false}
                          isActiveOffersWatch={false}
                          routeCard={"projectOneDetails"}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div>لا يوجد مشاريع</div>
                )}
              </div>

              <PaginationPage
                setBadgeView={setBadgeView}
                itmeCount={closeProjects.length / 3}
                itemperPage={3}
              />
            </Tab> */}
          </Tabs>
        </div>
        {/* ======= END TABS CONTENT ========= */}
      </div>
    </>
  );
};

export default MyProjectDetails;
