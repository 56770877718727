import { toast } from "react-toastify";
import axios from "../../axios";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import i18n from "i18n";

const ChatPanel = ({ activeUser, newChat, refetch }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const chatMessagesRef = useRef(null); // Ref for chat messages div

  useEffect(() => {
    console.log(activeUser);
    if (activeUser) {
      axios
        .get(`/chat/single/${activeUser?.id}`)
        .then((res) => {})
        .catch((err) => {
          if (err.response.status == 404) {
            if (newChat) {
              setMessages([]);
            } else {
              setMessages([]);
              toast.error("حدث خطأ ما");
            }
          } else {
          }
        });
    }
  }, []);

  useEffect(() => {
    console.log(activeUser);
    if (activeUser) {
      axios
        .get(`/chat/single/${activeUser.id}`)
        .then((res) => {
          setMessages(res.data.data.messages);
          scrollToBottom(); // Scroll to bottom when messages update
        })
        .catch((err) => {
          if (err.response.status == 404) {
            if (newChat) {
              setMessages([]);
            } else {
              setMessages([]);
              toast.error("حدث خطأ ما");
            }
          } else {
          }
        });
    }
  }, [activeUser]);

  const handleSendMessage = () => {
    if (message.trim() === "") return;

    if (!activeUser) {
      toast.loading(" يرجي الانتظار  ");
      return;
    }

    if (activeUser) {
      axios
        .post("/chat/send", {
          customer_id: activeUser.id,
          message: message,
        })
        .then((res) => {
          if (refetch) {
            refetch();
          }
          if (activeUser) {
            axios
              .get(`/chat/single/${activeUser.id}`)
              .then((res) => {
                setMessages(res.data.data.messages);

                scrollToBottom(); // Scroll to bottom when messages update
              })
              .catch(() => {
                setMessages([]);
                toast.error(" حدث خطأ ما في ارسال الرسالة");
              });
          }
        });
    }
    setMessage(""); // Clear the input field after sending the message
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <Link to={`/theExpert/${activeUser?.id}`} className="user-info">
          <img src={activeUser?.image} alt="User" className="user-avatar" />
          <span className="name-user">
            {i18n.language === "en"
              ? activeUser?.company_name_en == ""
                ? activeUser?.name
                : activeUser?.company_name_en
              : activeUser?.company_name_ar == ""
              ? activeUser?.name
              : activeUser?.company_name_ar}
          </span>
        </Link>
      </div>
      <div
        className="chat-messages"
        style={{ maxHeight: "450px", overflowY: "scroll" }}
        ref={chatMessagesRef}
      >
        {messages &&
          messages.map((message, index) => {
            return (
              <div
                key={index}
                className={`info-chat ${
                  message.sender.id === activeUser.id
                    ? "user-send"
                    : "support-chat"
                }`}
              >
                <div className={`message`}>{message.message}</div>
                <div className="date-send">{message.createdAt}</div>
              </div>
            );
          })}
      </div>
      <div className="input-area d-flex align-items-center gap-3 mt-5">
        <input
          type="text"
          className="form-control"
          placeholder="إكتب رسالتك.."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown} // Add keydown event listener
        />
        <button onClick={handleSendMessage} className="btn-main mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M11.8491 18.0254C10.8658 18.0254 9.47409 17.3338 8.37409 14.0254L7.77409 12.2254L5.97409 11.6254C2.67409 10.5254 1.98242 9.13376 1.98242 8.15043C1.98242 7.17543 2.67409 5.77543 5.97409 4.66709L13.0491 2.30876C14.8158 1.71709 16.2908 1.89209 17.1991 2.79209C18.1074 3.69209 18.2824 5.17543 17.6908 6.94209L15.3324 14.0171C14.2241 17.3338 12.8324 18.0254 11.8491 18.0254ZM6.36575 5.85876C4.04909 6.63376 3.22409 7.55043 3.22409 8.15043C3.22409 8.75043 4.04909 9.66709 6.36575 10.4338L8.46575 11.1338C8.64909 11.1921 8.79909 11.3421 8.85742 11.5254L9.55742 13.6254C10.3241 15.9421 11.2491 16.7671 11.8491 16.7671C12.4491 16.7671 13.3658 15.9421 14.1408 13.6254L16.4991 6.55043C16.9241 5.26709 16.8491 4.21709 16.3074 3.67543C15.7658 3.13376 14.7158 3.06709 13.4408 3.49209L6.36575 5.85876Z"
              fill="white"
            />
            <path
              d="M8.42565 12.0003C8.26732 12.0003 8.10898 11.942 7.98398 11.817C7.74232 11.5753 7.74232 11.1753 7.98398 10.9337L10.9673 7.94199C11.209 7.70033 11.609 7.70033 11.8507 7.94199C12.0923 8.18366 12.0923 8.58366 11.8507 8.82533L8.86732 11.817C8.75065 11.942 8.58398 12.0003 8.42565 12.0003Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatPanel;
