import CustomModal from "Components/CustomModal/CustomModal";
import CardOffer from "Pages/Home/OffersPackages/CardOffer";

import iconCurrancy from "../../assets/images/icons/dollar-circle.svg";
import "./ModalAcceptOffer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import iconSuccess from "../../assets/images/gif/success.gif";
import { Link } from "react-router-dom";
const ModalAcceptOffer = ({
  showModalAcceptOffer,
  handleCloseAcceptOffer,
  titleCard,
  image,
  priceNum,
}) => {
  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalAcceptOffer}
        onHide={handleCloseAcceptOffer}
        title=""
        newClass={"modal-accept-offer"}
      >
        <div className="main-modal-offer-accept">
          <div className="header-modal-success">
            <div className="icon-success">
              <img
                src={iconSuccess}
                width={"200px"}
                height={"200px"}
                alt="icon success"
              />
            </div>
            <div className="text-info-modal">
              <h2 className="title">تم قبول عرض المقاول بنجاح !</h2>
              <p className="text">
                تهانينا ! لقد قمت للتو بقبول عرض الشركة الخاص بمشروعك , يمكنك
                التواصل معه من هنا
              </p>
            </div>
          </div>
          {/* ========== START CARD DETAILS INFO MODAL ======== */}
          <div className="card-details-info-modal">
            {/* ======== START CARD DETAILS INFO ========== */}
            <CardOffer
              numDay={"3"}
              image={image}
              imageCardAlt={"image-card"}
              titleCard={titleCard}
              textCard={"مبنى سكني مكون من 10 أدوار"}
              rateNum={"4.8"}
              textCardTrue={false}
              cardTwoInfo={true}
              iconCurrancy={iconCurrancy}
              iconAlt={"iconCurrancy"}
              priceNum={priceNum}
              typePrice={"درهم"}
              textPrice={"السعر"}
              textButtonOne={"قبول"}
              textButtonTwo={"تواصل"}
              buttonTwoActive={false}
              buttonThreeActive={true}
            />
            {/* ======== END CARD DETAILS INFO ========== */}
            {/* <div className="button-conversation">
              <Link
                to={"/chat"}
                onClick={handleCloseAcceptOffer}
                state={ChatProfile}
                className="btn-main mt-0"
              >
                محادثة <FontAwesomeIcon icon={faCommentDots} />
              </Link>
            </div> */}
          </div>
          {/* ========== END CARD DETAILS INFO MODAL ======== */}
          <button
            onClick={handleCloseAcceptOffer}
            className="btn-main button-home w-100"
          >
            تم
          </button>
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalAcceptOffer;
