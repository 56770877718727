import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../../assets/images/main/image1.png";
import CardTab from "Components/CardTab/CardTab";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";

const Experts = ({ advisor, providers, contractor }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log(providers);
  }, [providers]);
  return (
    <div className="main-experts card-style margin-top-content">
      {/* ======= START ALL EXPERTS ========= */}
      <div className="all-experts">
        {/* ======= START HEADER TOP INFO  ======== */}
        <div className="header-top-info d-flex  justify-content-between  align-items-center  gap-2 ">
          <h2 className="title">{t("allExperts")}</h2>
          <Link to="/allExperts" className="link-all">
            {t("allExperts")}
          </Link>
        </div>
        {/* ======= END HEADER TOP INFO  ======== */}
        {/* ======= START TABS CONTENT ========= */}
        <div className="tabs-content tabs-content-cards">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="profile" title={t("contractors")}>
              {/* =========== START TAB CARDS ========== */}
              <div className="tab-cards">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={15}
                  freeMode={true}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor={true}
                  modules={[FreeMode, Pagination, Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                  }}
                  className="mySwiper"
                  dir={i18n.language == "ar" ? "rtl" : "ltr"}
                >
                  {contractor?.length > 0 ? (
                    contractor?.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          {/* ======= START CARD ONE TAB ======== */}
                          <CardTab
                            imgCard={item.image}
                            titleCard={
                              (i18n.language == "en"
                                ? item.company_name_en
                                : item.company_name_ar) || item.name
                            }
                            numRate={item?.rate}
                            textInfoBottom={t("activity")}
                            titleInfoBottom={"بناء"}
                            textInfoBottom2={t("emirate")}
                            titleInfoBottom2={item.city}
                            textInfoBottom3={t("area")}
                            titleInfoBottom3={item.region}
                            is_favorite={item.is_favorite}
                            productId={item.id}
                            routeCard={`/theExpert/${item.id}`}
                            jobType={t("contractors")}
                            allData={item}
                          />
                          {/* ======= END CARD ONE TAB ======== */}
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <div className="thereisnojobs">
                      <h4>
                        {t("noJobsPrimyam1")} {t(`contractor`)}{" "}
                        {t("noJobsPrimyam2")}
                      </h4>
                      <Link to={"/allExperts"} className="btn-main">
                        {t("Browseallexperts")}
                      </Link>
                    </div>
                  )}
                </Swiper>
              </div>
              {/* =========== END TAB CARDS ========== */}
            </Tab>
            <Tab eventKey="profile-2" title={t("consultants")}>
              <div className="tab-cards">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={15}
                  freeMode={true}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor={true}
                  modules={[FreeMode, Pagination, Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                  }}
                  className="mySwiper"
                  dir={i18n.language == "ar" ? "rtl" : "ltr"}
                >
                  {advisor?.length > 0 ? (
                    advisor?.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          {/* ======= START CARD ONE TAB ======== */}
                          <CardTab
                            imgCard={item.image}
                            titleCard={
                              (i18n.language == "en"
                                ? item.company_name_en
                                : item.company_name_ar) || item.name
                            }
                            numRate={item?.rate}
                            textInfoBottom={t("activity")}
                            titleInfoBottom={"بناء"}
                            textInfoBottom2={t("emirate")}
                            titleInfoBottom2={item.city}
                            textInfoBottom3={t("area")}
                            titleInfoBottom3={item.region}
                            routeCard={`/theExpert/${item.id}`}
                            jobType={t("consultants")}
                            allData={item}
                          />
                          {/* ======= END CARD ONE TAB ======== */}
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <div className="thereisnojobs">
                      <h4>
                        {t("noJobsPrimyam1")} {t(`advisor`)}{" "}
                        {t("noJobsPrimyam2")}
                      </h4>
                      <Link to={"/allExperts"} className="btn-main">
                        {t("Browseallexperts")}
                      </Link>
                    </div>
                  )}
                </Swiper>
              </div>
            </Tab>
            <Tab eventKey="profile-3" title={t("suppliers")}>
              <div className="tab-cards">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={15}
                  freeMode={true}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor={true}
                  modules={[FreeMode, Pagination, Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                  }}
                  className="mySwiper"
                  dir={i18n.language == "ar" ? "rtl" : "ltr"}
                >
                  {providers?.length > 0 ? (
                    providers?.map((item, index) => {
                      console.log(item);
                      return (
                        <SwiperSlide key={index}>
                          {/* ======= START CARD ONE TAB ======== */}
                          <CardTab
                            imgCard={item.image}
                            titleCard={
                              (i18n.language == "en"
                                ? item.company_name_en
                                : item.company_name_ar) || item.name
                            }
                            numRate={item?.rate}
                            textInfoBottom={t("activity")}
                            titleInfoBottom={"بناء"}
                            textInfoBottom2={t("emirate")}
                            titleInfoBottom2={item.city}
                            textInfoBottom3={t("area")}
                            titleInfoBottom3={item.region}
                            routeCard={`/theExpert/${item.id}`}
                            jobType={t("suppliers")}
                            allData={item}
                          />
                          {/* ======= END CARD ONE TAB ======== */}
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <div className="thereisnojobs">
                      <h4>
                        {t("noJobsPrimyam1")} {t(`providers`)}{" "}
                        {t("noJobsPrimyam2")}
                      </h4>
                      <Link to={"/allExperts"} className="btn-main">
                        {t("Browseallexperts")}
                      </Link>
                    </div>
                  )}
                </Swiper>
              </div>
            </Tab>
          </Tabs>
        </div>
        {/* ======= END TABS CONTENT ========= */}
      </div>
      {/* ======= END ALL EXPERTS ========= */}
    </div>
  );
};

export default Experts;
