import imgUser from "../../assets/images/user/userImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./RateCardUser.css";
import moment from "moment";

const RateCardUser = ({ item }) => {
  function createArray(numElements) {
    return new Array(numElements).fill(null); // Fill with null or any placeholder value
  }
  const Stars = createArray(item?.rate).map((item, index) => {
    return (
      <div className="icon-rate" key={index}>
        <FontAwesomeIcon icon={faStar} />
      </div>
    );
  });
  return (
    <>
      {/* START RATE CARD USER ONE  */}
      <div className="rate-card-user-one">
        {/* ========== START INFO USER ======== */}
        <div className="info-user d-flex  align-items-center gap-2">
          <div className="img-user">
            <img
              src={item?.person_image}
              alt="img user"
              className="object-fit-cover  rounded-circle"
              width={"70px"}
              height={"70px"}
            />
          </div>
          <div className="content-info">
            <h2 className="title-name"> {item?.person_name}</h2>
            <p className="date-info">
              {moment(item?.created_at).format("DD/MM/YYYY")}
            </p>
            <div className="rate-user d-flex  align-items-center gap-1">
              {Stars}
            </div>
          </div>
        </div>
        {/* ========== END INFO USER ======== */}
        <div className="content-text-user">{item?.comment}</div>
      </div>
      {/* END RATE CARD USER ONE  */}
    </>
  );
};

export default RateCardUser;
