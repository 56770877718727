import HeaderPages from "Components/HeaderPages/HeaderPages";
import "./Experts.css";
import { ProgressBar, Tab, Tabs } from "react-bootstrap";
import "./Experts.css";
import img from "../../assets/images/main/image1.png";
import imgGallery from "../../assets/images/main/image001.png";
import iconLocation from "../../assets/images/icons/location.svg";
import iconPhone from "../../assets/images/icons/call-calling.svg";
import iconSms from "../../assets/images/icons/sms-tracking.svg";
import pdf from "../../assets/images/icons/pdf.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faStar } from "@fortawesome/free-solid-svg-icons";
import PaginationPage from "Components/Pagination/Pagination";
import RateCardUser from "Components/RateCardUser/RateCardUser";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import MapView from "Components/mapPicker/MapView";
import verified from "../../assets/images/icons/verified.png";
import crown from "../../assets/images/icons/crown (1).png";
import CardSkelaton from "Components/Skelaton/CardSkelaton";
const TheExpert = () => {
  const { t, i18n } = useTranslation();
  const [ProviderData, setProviderData] = useState();
  const [loading, setLoading] = useState(false);
  const galleryProducts = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
  ];

  const progressRate = [
    { id: 0, progressCount: 80, numRate: 5 },
    { id: 1, progressCount: 60, numRate: 4 },
    { id: 2, progressCount: 50, numRate: 3 },
    { id: 3, progressCount: 30, numRate: 2 },
    { id: 4, progressCount: 20, numRate: 1 },
  ];
  const location = useLocation();
  const idExpert = useParams();
  useEffect(() => {
    console.log(idExpert);
    axiosInstance
      .get(`/provider/${idExpert.idExpert}`)
      .then((res) => {
        console.log(res.data.data);
        setProviderData(res.data.data);
        setLoading(true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
        }
      });
  }, []);
  return (
    <>
      <HelmetInfo titlePage={t("expertTitle")} />

      <div className="the-expert-page">
        <HeaderPages
          titlePage={t("expertTitle")}
          routePage={"/allExperts"}
          mainTitlePage={t("allExpertsTitle")}
          showMainTitlePage={true}
        />

        {!loading ? (
          [1].map((e, index) => {
            return <CardSkelaton key={index} />;
          })
        ) : (
          <div
            className="all-expert-page card-style"
            style={
              ProviderData?.show_in_home === 1
                ? { border: "2px solid #eda600" }
                : null
            }
          >
            {ProviderData?.show_in_home === 1 ? (
              <div className="ribbonx"></div>
            ) : null}
            <div className="header-top-expert mb-3 d-flex  justify-content-between  align-items-center gap-3 flex-wrap ">
              <div className="info-right d-flex  align-items-center  gap-3">
                <div className="img">
                  <img
                    src={ProviderData?.image}
                    alt="img"
                    className=" object-fit-cover "
                    width={"80px"}
                    height={"80px"}
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div className="content-info">
                  <h1 className="title">
                    {(i18n.language == "en"
                      ? ProviderData?.company_name_en
                      : ProviderData?.company_name_ar) || ProviderData?.name}
                    {ProviderData?.is_verified ? (
                      <img src={verified} alt="" className="p-1" />
                    ) : null}
                  </h1>
                  <div className="d-flex mb-2 joptitleparent">
                    <p className="joptitle">{t("job")} : </p>
                    <p className="joptitleT">{t(`${ProviderData?.type}`)}</p>
                  </div>
                  {(() => {
                    if (ProviderData?.type === "provider") {
                      return (
                        <div
                          className="d-flex mb-2"
                          style={{ color: "#565656", fontWeight: "500" }}
                        >
                          {(() => {
                            if (ProviderData?.category != undefined || null) {
                              return (
                                <>
                                  <p>{t("typeofsupply")} : </p>
                                  <p className="ms-1 me-1">
                                    {ProviderData?.category}
                                  </p>
                                </>
                              );
                            }
                          })()}
                        </div>
                      );
                    }
                  })()}
                  <div className="rate d-flex  align-items-center  gap-2 mt-1">
                    <div className="icon-star">
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                    <span className="num-rate">{ProviderData?.rate}</span>
                  </div>
                </div>
              </div>
              {ProviderData?.show_in_home === 1 ? (
                <div className="premiemAccount">
                  حساب مميز
                  <div className="p-1">
                    <img src={crown} alt="" />
                  </div>
                </div>
              ) : null}

              {/* <div className="button-conversation">
              <Link
                to={{
                  pathname: "/chat",
                }}
                state={{ ...ProviderData, newChat: true }}
                className="btn-main mt-0"
              >
                {t("conversationButton")}{" "}
                <FontAwesomeIcon icon={faCommentDots} />
              </Link>
            </div> */}
            </div>
            {/* ============= START ROW ============ */}
            <div className="row g-3">
              {/* =========== START COL ============= */}
              <div className="col-12 col-xl-8">
                {/* ======= START TABS CONTENT ========= */}
                <div className="tabs-content tabs-content-cards">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="profile" title={t("informationTabTitle")}>
                      {/* ======= START TAB CONTENT ONE =========== */}
                      <div className="tab-content-one">
                        {/* START CONTENT ONE */}
                        {ProviderData?.categories?.length != 0 && (
                          <div className="content-one">
                            <h2 className="title">{t("typeofsupply")}</h2>
                            {ProviderData?.categories?.map((e, i) => {
                              return (
                                <p className="text" key={i}>
                                  {e.title}
                                </p>
                              );
                            })}
                          </div>
                        )}
                        <div className="content-one">
                          <h2 className="title">{t("introTitle")}</h2>
                          <p className="text">
                            {ProviderData?.about || t("nothing")}
                          </p>
                        </div>
                        {/* END CONTENT ONE */}
                        {/* START CARDS TAB INFO the previous code is comented in dark (; */}

                        {/* END CARDS TAB INFO  */}

                        {/* START CONTENT ONE */}

                        {/* END CONTENT ONE */}
                      </div>
                      {/* ======= END TAB CONTENT ONE =========== */}
                    </Tab>
                    <Tab eventKey="profile-2" title={t("portfolioTabTitle")}>
                      {/* ========== START Business gallery ========= */}
                      <div className="business-gallery mt-4">
                        {/* ========== START ROW ========== */}
                        <div className="row g-3">
                          {ProviderData?.work_profile?.length > 0 &&
                          ProviderData?.work_profile[0] != "" ? (
                            ProviderData?.work_profile.map(
                              (itemGallery, index) => {
                                return (
                                  <div
                                    className="col-12 col-md-6 col-lg-4"
                                    key={index}
                                  >
                                    {/* ========= START PRODUCT GALLERY ONE ========= */}
                                    <a
                                      href={itemGallery}
                                      target="_blank"
                                      className="product-gallery-one"
                                    >
                                      {/* ========= START IMAGE PRODUCT GALLRRY ======= */}
                                      <div className="image-product-gallery">
                                        <img
                                          src={itemGallery}
                                          alt="imageGallery"
                                          className="object-fit-cover "
                                          style={{
                                            borderRadius: "10px",
                                            width: "280px",

                                            height: "180px",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                          }}
                                        />
                                      </div>
                                      {/* ========= END IMAGE PRODUCT GALLRRY ======= */}
                                      <div className="info-product-gallery mt-3">
                                        <h2 className="title"></h2>
                                      </div>
                                    </a>
                                    {/* ========== END PRODUCT GALLERY ONE ========= */}
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <NoResults text={t("No Work Portfolio")} />
                          )}
                        </div>
                        {/* ========== END ROW ========== */}
                      </div>
                      {/* ========== END Business gallery ========= */}
                    </Tab>
                    {/* <Tab eventKey="profile-3" title={t("reviewsTabTitle")}>
        
                    <div className="rates-cards-user mt-4">
               
                      <div className="row g-3">
                        {galleryProducts.map((item,index) => {
                          return (
                            <div className="col-12" key={index}>
                              <RateCardUser />
                            </div>
                          );
                        })}
                      </div>
          

                      <PaginationPage itmeCount={5} />
                    </div>
            
                  </Tab> */}
                  </Tabs>
                </div>
                {/* ======= END TABS CONTENT ========= */}
              </div>
              {/* =========== END COL ============= */}
              {/* =========== START COL ============= */}
              <div className="col-12 col-xl-4">
                {/* ========= START LEFT INFO CONTENT =========== */}
                <div className="left-info-content">
                  {/* ============ START INFO BOTTOM ========== */}
                  <div
                    className="bottom-info d-flex  justify-content-around   align-items-center  gap-3 "
                    style={{ alignItems: "stretch" }}
                  >
                    {ProviderData?.type == "provider" &&
                      ProviderData?.category && (
                        <>
                          <div className="info-bottom-one">
                            <p className="text">{t("activityLabel")}</p>
                            <h2 className="title">{ProviderData?.category}</h2>
                          </div>

                          <div
                            className="line"
                            style={{
                              width: "1px",
                              height: "50px",
                              background: "black",
                            }}
                          ></div>
                        </>
                      )}

                    <div className="info-bottom-one">
                      <p className="text">{t("emirateLabel")}</p>
                      <h2 className="title">{ProviderData?.city}</h2>
                    </div>
                    <div
                      className="line"
                      style={{
                        width: "1px",
                        height: "50px",
                        background: "black",
                      }}
                    ></div>
                    <div className="info-bottom-one">
                      <p className="text">{t("areaLabel")}</p>
                      <h2 className="title">{ProviderData?.region}</h2>
                    </div>
                  </div>
                  {/* ============ END INFO BOTTOM ========== */}
                  {/* ========== START RATE PROGRESS BAR ========= */}
                  <div
                    style={{ borderTop: "1px solid #e0e1ed", height: "10px" }}
                  >
                    {/* {progressRate.map((itemProg,index) => {
                    return (
                      <div
                        className="info-progress d-flex align-items-center gap-2"
                        key={index}
                      >
                        <div className="info-text-progress d-flex  align-items-center gap-1">
                          <div className="icon-star">
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                          <h2 className="num-per-1">{itemProg.numRate}</h2>
                        </div>
                        <ProgressBar
                          striped
                          variant="success"
                          now={itemProg.progressCount}
                        />
                      </div>
                    );
                  })} */}
                    {/* <div className="num-total-rate d-flex  flex-column  justify-content-center  align-items-center gap-1">
                    <div className="total-rate">{ProviderData?.rate}</div>
                    <div className="counte-rate">(4) تقييم</div> 
                  </div> */}
                  </div>
                  {/* ========== END RATE PROGRESS BAR ========= */}
                  {/* ========== START CONTACT INFO ========== */}
                  <div
                    className="contact-info"
                    style={{ borderTop: "0px !important" }}
                  >
                    {/* ========= START MAP LOCATION ======== */}
                    <div className="map-location">
                      <MapView
                        lat={ProviderData?.company_lat}
                        lng={ProviderData?.company_lng}
                      />
                    </div>
                    {/* ========= END MAP LOCATION ======== */}
                    {/* ========= START ALL INFO CONTACT ========= */}
                    <div className="all-info-contact d-flex  gap-4 flex-column">
                      {/* ===== START INFO CONTACT ONE ===== */}
                      <div className="info-contact-one d-flex align-items-center gap-3">
                        <div className="icon-img">
                          <img src={iconLocation} alt="icon Location" />
                        </div>
                        <div className="info-text">
                          <p className="text-cont">
                            {ProviderData?.company_address ||
                              t("Not specified")}
                          </p>
                        </div>
                      </div>
                      {/* ===== END INFO CONTACT ONE ===== */}
                      {/* ===== START INFO CONTACT ONE ===== */}
                      <div className="info-contact-one d-flex align-items-center gap-3">
                        <div className="icon-img">
                          <img src={iconPhone} alt="icon phone" />
                        </div>
                        <div className="info-text">
                          <h2 className="title">{t("phoneNumberLabel")}</h2>
                          <p className="text-cont">
                            {" "}
                            {ProviderData?.company_phone || t("Not specified")}
                          </p>
                        </div>
                      </div>
                      {/* ===== END INFO CONTACT ONE ===== */}
                      {/* ===== START INFO CONTACT ONE ===== */}
                      <div className="info-contact-one d-flex align-items-center gap-3">
                        <div className="icon-img">
                          <img src={iconSms} alt="icon sms" />
                        </div>
                        <div className="info-text">
                          <h2 className="title">{t("emailLabel")}</h2>
                          <p className="text-cont">
                            {ProviderData?.company_email || t("Not specified")}
                          </p>
                        </div>
                      </div>
                      <div className="info-contact-one d-flex align-items-center gap-3">
                        <div className="icon-img">
                          <img src={pdf} alt="icon sms" />
                        </div>
                        <div className="info-text">
                          <h2 className="title">{t("company_profile")}</h2>
                          {ProviderData?.company_profile ? (
                            <a
                              href={ProviderData?.company_profile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("showcompany_profile")}
                            </a>
                          ) : (
                            <p className="text-cont">{t("Not specified")}</p>
                          )}
                        </div>
                      </div>
                      {/* ===== END INFO CONTACT ONE ===== */}
                    </div>
                    {/* ========= END ALL INFO CONTACT ========= */}
                  </div>
                  {/* ========== END CONTACT INFO ========== */}
                </div>
                {/* ========= END LEFT INFO CONTENT =========== */}
              </div>
              {/* =========== END COL ============= */}
            </div>
            {/* ============= END ROW ============ */}
          </div>
        )}
      </div>
    </>
  );
};

export default TheExpert;
