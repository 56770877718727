import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalSuccess from "Components/Success/ModalSuccess";
import successIcon from "../../assets/images/gif/03.gif";
import sharingloginavatar from "../../assets/images/main/sharingloginavatar.jpg";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ModalSendFinancialOffer = ({
  showModal,
  handleCloseModal,
  projectId,
  startDatee,
  endDate,
  refetch,
}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    inputTitleMessage: "",
    textAreaDescription: "",
  };
  const token = useSelector((state) => state.auth.token);

  const validationSchema = Yup.object().shape({
    textAreaDescription: Yup.string()
      .min(10, t("minContentError"))
      .max(200, t("maxContentError"))
      .required(t("requiredContent")),

    inputTitleMessage: Yup.string().required(t("requiredTitle")),
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesError, setSelectedFilesError] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startDateError, setstartDateError] = useState(false);
  const [price, setPrice] = useState("");

  const handleSubmit = (values) => {
    const isEmpty = !values.inputTitleMessage && !values.textAreaDescription;

    if (isEmpty) {
      console.log(t("addValueInput"));
    } else {
      console.log(values);
      if (!selectedFiles?.length || !startDate) {
        if (!selectedFiles?.length) {
          setSelectedFilesError(true);
        }
        if (!startDate) {
          setstartDateError(true);
        }
        return;
      }
      const formData = new FormData();
      formData.append("project_id", projectId);
      if (price !== "" || !price.includes("NaN")) {
        formData.append("price", values.inputTitleMessage);
      }
      console.log(moment(startDate).locale("en").format("DD/MM/YYYY"));
      formData.append("content", values.textAreaDescription);
      formData.append(
        "end_date",
        moment(startDate).locale("en").format("YYYY-MM-DD")
      );
      formData.append("files[0]", selectedFiles[0].file);
      setLoading(true);
      axiosInstance
        .post("/offer", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
            "Accept-Language": i18n.language,
          },
        })
        .then(() => {
          handleCloseModal();
          handleOpenModalSuccess();
          setLoading(false);
          refetch();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.error(error.response.data?.message);
          }
          let messages = [];
          console.log(error.response.data?.errors);
          for (let key in error.response.data?.errors) {
            messages.push(error.response.data?.errors[key][0]);
          }
          console.log(messages);
          setLoading(false);

          messages.map((item) => {
            toast.error(t(item));
            console.log("---------------------------");
            console.log(item);
            console.log("---------------------------");
          });
        });
    }
  };

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
    handleCloseModal();
  };

  const handleOpenModalSuccess = () => {
    setShowModalSuccess(true);
  };

  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        handleCloseModalSuccess={handleCloseModalSuccess}
        titleInfo={t("successTitle")}
        isAddText={false}
        textInfo={""}
        titleButton={t("successButton")}
        buttonRoute={false}
        routeDir={false}
        successIcon={successIcon}
        isActiveMainButton={true}
      />
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={t("financialOfferTitle")}
        newClass={"card-details-info-modal1 modalAccount"}
      >
        <div className="form-info-contact mt-4">
          {(() => {
            if (token) {
              return (
                <FormField
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <div className="row g-3">
                    <div className="col-12 col-md-12">
                      <InputFiled
                        label={t("financialValue")}
                        name="inputTitleMessage"
                        type="text"
                        placeholder=""
                        success
                        price
                        iconInput={false}
                      />
                    </div>
                    <div className="col-12 col-md-12 all-calender">
                      <div className="calender-one position-relative">
                        <DatePicker
                          minDate={moment(new Date()).add(1, "days").toDate()}
                          selected={startDate}
                          placeholderText={t("offerEndDate")}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="icon-calender">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <FileUpload
                        title={t("attachFile")}
                        text={"pdf/doc"}
                        showText={false}
                        SelectedData={setSelectedFiles}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <TextAreaInput
                        label=""
                        name="textAreaDescription"
                        type="text"
                        placeholder={t("notesPlaceholder")}
                        success
                      />
                    </div>
                  </div>
                  <button
                    className="btn-main w-100"
                    type={"submit"}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <span className="loader"></span>
                    ) : (
                      t("sendOffer")
                    )}
                  </button>
                </FormField>
              );
            } else {
              return (
                <div className="moneyoffercondition text-center">
                  <img
                    src={sharingloginavatar}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                  <h4>{t("sharinglogincondition")}</h4>
                  <Link to={"/login"} className="btn-main m-auto">
                    {t("sharingloginconditionbtn")}
                  </Link>
                </div>
              );
            }
          })()}
        </div>
      </CustomModal>
    </>
  );
};

export default ModalSendFinancialOffer;
