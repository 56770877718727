import PaginationPage from "Components/Pagination/Pagination";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import CardProductDetails from "Pages/ProductDetailsPage/CardProductDetails";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import CardSkelaton from "Components/Skelaton/CardSkelaton";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const WorkProjects = () => {
  const projectsCard = [{ id: 0 }, { id: 2 }, { id: 3 }];
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState([]);
  const [badgeView, setBadgeView] = useState(3);
  const [openProjects, setOpenProjects] = useState([]);
  const [closeProjects, setCloseProjects] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/project", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.otherprojects);
        setAllProjects(res.data.otherprojects?.all);
        setOpenProjects(res.data.otherprojects?.current);
        setCloseProjects(res.data.otherprojects?.finished);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="my-projects card-style margin-top-content position-relative">
        <div className="button-order d-flex gap-2">
          <Link to={"/myProjects"} className="btn-main button-home">
            {t("myProjects")}
          </Link>
          <Link
            to={"/myProjects/projectManagementRequestsDetails"}
            className="btn-main button-home"
          >
            {t("projectManagementRequestsDetails")}
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        </div>
        {/* ======= START TABS CONTENT ========= */}
        <div className="tabs-content">
          <Tabs
            defaultActiveKey="profile"
            onSelect={(k) => setBadgeView(3)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="profile" title={t("allProjects")}>
              {/* ========= START ALL PROJECTS CARD ========== */}
              <div className="all-projects-card">
                {/* ======= START MAIN PROJECTS CARD ======== */}
                <div className="main-projects-card">
                  {loading ? (
                    [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                  ) : allProjects?.length ? (
                    allProjects
                      ?.slice(badgeView - 3, badgeView)
                      .map((item, index) => {
                        return (
                          <div className="card-details-pro" key={index}>
                            <CardProductDetails
                              projectId={item.id}
                              img={item?.images[0] || img}
                              altImg={"img Card"}
                              badgeText={item?.category}
                              titleCard={item?.title}
                              titleAddress={`${item.city} , ${item.region}`}
                              iconDollar={iconDollar}
                              priceCard={item.price}
                              typePrice={"درهم"}
                              textPrice={t("expectedValue")}
                              iconCalendar={iconCalendar}
                              dateCard={item.end_date}
                              textInfoDate={t("expectedDeliveryDate")}
                              numWatch={item.views}
                              textWatch={t("views")}
                              numOffer={item.offers_count}
                              textOffer={t("offers")}
                              numDay={`${t("remaining")} ${"a"} ${t("days")}`}
                              isActiveMainButtons={false}
                              isActiveButtonUpdateAndStop={false}
                              isActiveFinalCard={true}
                              dateCard2={"01/01/2025"}
                              textInfoDate2={t("projectStartDate")}
                              dateCard3={"01/01/2025"}
                              textInfoDate3={t("dateOfAcceptanceOfOffers")}
                              isActiveDate={false}
                              isActiveOffersWatch={false}
                              status={item.status}
                              routeCard={"projectOneDetails"}
                              formWorkProject
                              Owener={item?.customer}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <NoResults text={"noProjects"} />
                  )}
                </div>
                {/* ======= END MAIN PROJECTS CARD ======== */}
                <PaginationPage
                  setBadgeView={setBadgeView}
                  itmeCount={allProjects?.length / 3}
                  itemperPage={3}
                />
              </div>
              {/* ========= END ALL PROJECTS CARD ========== */}
            </Tab>
            <Tab eventKey="profile-2" title={t("openProjects")}>
              <div className="all-projects-card">
                {/* ======= START MAIN PROJECTS CARD ======== */}
                <div className="main-projects-card">
                  {loading ? (
                    [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                  ) : openProjects?.length ? (
                    openProjects
                      ?.slice(badgeView - 3, badgeView)
                      .map((item, index) => {
                        return (
                          <div className="card-details-pro" key={index}>
                            <CardProductDetails
                              projectId={item.id}
                              img={item?.images[0] || img}
                              altImg={"img Card"}
                              badgeText={item?.category}
                              titleCard={item?.title}
                              titleAddress={`${item.city} , ${item.region}`}
                              iconDollar={iconDollar}
                              priceCard={item.price}
                              typePrice={"درهم"}
                              textPrice={t("expectedValue")}
                              iconCalendar={iconCalendar}
                              dateCard={item.end_date}
                              textInfoDate={t("expectedDeliveryDate")}
                              numWatch={item.views}
                              textWatch={t("views")}
                              numOffer={item.offers_count}
                              textOffer={t("offers")}
                              numDay={`${t("remaining")} ${"a"} ${t("days")}`}
                              isActiveMainButtons={false}
                              isActiveButtonUpdateAndStop={false}
                              isActiveFinalCard={true}
                              dateCard2={"01/01/2025"}
                              textInfoDate2={t("projectStartDate")}
                              dateCard3={"01/01/2025"}
                              textInfoDate3={t("dateOfAcceptanceOfOffers")}
                              isActiveDate={false}
                              isActiveOffersWatch={false}
                              routeCard={"projectOneDetails"}
                              status={item.status}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <NoResults text={"noProjects"} />
                  )}
                </div>
                {/* ======= END MAIN PROJECTS CARD ======== */}
                <PaginationPage
                  setBadgeView={setBadgeView}
                  itmeCount={openProjects?.length / 3}
                  itemperPage={3}
                />
              </div>
            </Tab>
            <Tab eventKey="profile-3" title={t("closedProjects")}>
              {/* ======= START MAIN PROJECTS CARD ======== */}
              <div className="main-projects-card">
                {loading ? (
                  [1, 2, 3].map((item, index) => <CardSkelaton key={index} />)
                ) : closeProjects?.length ? (
                  closeProjects
                    ?.slice(badgeView - 3, badgeView)
                    .map((item, index) => {
                      return (
                        <div className="card-details-pro" key={index}>
                          <CardProductDetails
                            img={item?.images[0] || img}
                            altImg={"img Card"}
                            badgeText={item?.category}
                            titleCard={item?.title}
                            titleAddress={`${item.city} , ${item.region}`}
                            iconDollar={iconDollar}
                            priceCard={item.price}
                            status={item.status}
                            typePrice={"درهم"}
                            textPrice={t("expectedValue")}
                            iconCalendar={iconCalendar}
                            dateCard={item.end_date}
                            textInfoDate={t("expectedDeliveryDate")}
                            numWatch={item.views}
                            textWatch={t("views")}
                            numOffer={item.offers_count}
                            textOffer={t("offers")}
                            numDay={`${t("remaining")} ${"a"} ${t("days")}`}
                            isActiveMainButtons={false}
                            isActiveButtonUpdateAndStop={false}
                            isActiveFinalCard={true}
                            dateCard2={"01/01/2025"}
                            textInfoDate2={t("projectStartDate")}
                            dateCard3={"01/01/2025"}
                            textInfoDate3={t("dateOfAcceptanceOfOffers")}
                            isActiveDate={false}
                            isActiveOffersWatch={false}
                            projectId={item.id}
                            routeCard={"projectOneDetails"}
                          />
                        </div>
                      );
                    })
                ) : (
                  <NoResults text={"noProjects"} />
                )}
              </div>
              {/* ======= END MAIN PROJECTS CARD ======== */}
              <PaginationPage
                setBadgeView={setBadgeView}
                itmeCount={closeProjects?.length / 3}
                itemperPage={3}
              />
            </Tab>
          </Tabs>
        </div>
        {/* ======= END TABS CONTENT ========= */}
      </div>
    </>
  );
};

export default WorkProjects;
