import { useEffect, useState } from "react";
import "./Packages.css";
import PackagesCard from "Components/PackagesCard/PackagesCard";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "Pages/Wallet/Stripe";
import { loadStripe } from "@stripe/stripe-js";
import imgIcon from "../../assets/images/icons/diamond.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import SubscribeModal from "Pages/MyAccount/ModalAccount/SubscribeModal";
import CustomModal from "Components/CustomModal/CustomModal";

import axios from "axios";
import i18n from "i18n";

const PackagesCards = () => {
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState();
  const [clientSecret, setclientSecret] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [ActiveId, setActiveId] = useState(0);
  const [isYearly, setIsYearly] = useState(false);
  const [active, setActive] = useState();
  const [ClientLoadingg, setClientLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const stripePromise = loadStripe(
    "pk_live_51P5XBHRozbqwhoPVH68Crl9g2kO2lLn8dWnD7zAtlDt05lqA2Why33vUdZdG85z4C6mz4rWz02Puo3vQZ1R4m2Xm00JT6fxYqC"
  );
  const [activePeriod, setActivePeriod] = useState("");
  const [stripe_ID, setstripe_ID] = useState();
  const [UserData, setUserData] = useState();
  useEffect(() => {
    axiosInstance
      .get("/profile")
      .then((res) => res.data)
      .then((data) => {
        setstripe_ID(data.data.stripe_id);
        setUserData(data.data);
      });
  }, []);
  const HandleAmount = (num, setLoaidng) => {
    setAmount(num);

    if (num != null) {
      console.log("hello");

      setClientLoading(true);
      axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          {
            amount: num * 100,
            currency: "aed",
            "payment_method_types[]": "card",
            ...(stripe_ID ? { customer: stripe_ID } : {}),
            description: `ID: ${UserData?.id}, Email: ${
              UserData?.email
            }, Name: ${
              UserData?.name
            }, Date: ${new Date().toLocaleDateString()}, Time: ${new Date().toLocaleTimeString()}, Duration: ${activePeriod}, Price : ${price}`,
          },
          {
            headers: {
              Authorization:
                "Bearer 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",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setclientSecret(res.data.client_secret);
          console.log(res.data.client_secret);
          setTimeout(() => {
            setClientLoading(false);
            setPaymentModal(true);
            setShowModal(false);
            setLoaidng(false);
          }, 500);
        })
        .catch((err) => {
          toast.error(t("Failed to subscribe"));
          setLoaidng(false);
        });
    }
  };
  const handleToggle = () => {
    setIsYearly(!isYearly);
  };
  const [data, setData] = useState([]);

  const packagesI = [
    { id: 0, ribbon: false },
    { id: 2, ribbon: true, ribbonText: "نرشحها لك" },
    { id: 3, ribbon: false },
  ];
  useEffect(() => {
    axiosInstance
      .get("/packages")
      .then((res) => {
        setData(res.data.packages);
        console.log(res.data);
      })
      .catch((err) => {});
  }, []);
  const handleSubcribe = (type) => {
    axiosInstance
      .post("/subscription", { package_id: data[0]?.id, duration: type })
      .then((res) => {
        toast.success("تم الاشتراك بنجاح");
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.error("انت مشترك في باقة اخري بالفعل");
        }
      });
  };
  const handleAfterPay = (id, setIsProcessing) => {
    setIsProcessing(true);
    axiosInstance
      .post("/subscription", {
        package_id: ActiveId,
        duration: activePeriod,
        payment_method: "stripe",
        transaction_id: id,
      })
      .then((res) => {
        toast.success(t("Subscribed successfully"));
        setIsProcessing(false);

        setPaymentModal(false);
      })
      .catch((error) => {
        toast.error(t("Failed to subscribe"));
        setIsProcessing(false);
        setPaymentModal(false);
      });
  };
  const { t } = useTranslation();
  return (
    <>
      <HelmetInfo titlePage={"الباقات"} />
      <CustomModal show={paymentModal} onHide={() => setPaymentModal(false)}>
        {ClientLoadingg ? (
          <div>Loading</div>
        ) : (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              appearance: { theme: "flat" },
            }}
          >
            <CheckoutForm amount={amount} handleAfterPay={handleAfterPay} />
          </Elements>
        )}
      </CustomModal>
      {activePeriod && (
        <SubscribeModal
          price={price}
          duration={activePeriod}
          handleCloseModalEditWork={() => setShowModal(null)}
          ShowModal={showModal}
          HandleAmount={HandleAmount}
        />
      )}
      <div className="pricing-cards margin-top-content">
        {/* <div className="switch-input d-flex align-items-center gap-2">
          <h2 className={`title-switch ${isYearly ? "" : "active"}`}>
            إشتراك شهري
          </h2>
          <label className="switch">
            <input type="checkbox" checked={isYearly} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
          <h2 className={`title-switch ${isYearly ? "active" : ""}`}>
            إشتراك سنوي
          </h2>
        </div> */}
        <div className="all-archives margin-top-content">
          <div className="tabs-content">
            <h2>{t("Packages")}</h2>
            <Tabs
              defaultActiveKey="now"
              id="uncontrolled-tab-example"
              className="mt-3 mb-4"
            >
              <Tab eventKey="now" title={t("Monthly")}>
                <div className="all-subscriptions">
                  <div className="row g-3">
                    {data?.length > 0 ? (
                      <div className="row">
                        {data?.map((item, index) => {
                          console.log(data);
                          if (item?.monthly == 0) {
                            return;
                          }
                          console.log(item);
                          return (
                            <div
                              key={index}
                              className={`${
                                data.length == 1
                                  ? "col-12"
                                  : data.length == 2
                                  ? "col-lg-6 col-md-12"
                                  : "col-lg-4 col-md-6 col-12"
                              }`}
                            >
                              <PackagesCard
                                imgIcon={imgIcon}
                                titlePackage={
                                  i18n.language === "en"
                                    ? item.title_tr.en
                                    : item.title_tr.ar
                                }
                                textPackage={item?.description}
                                numPricing={item?.monthly}
                                dateType={data[0]?.duration}
                                moneyType={t("AED")}
                                iconTypeCheck={faCheck}
                                textIconPricing={"إظهار بالبداية عند البحث"}
                                textButton={t("subscribe now")}
                                newClass={"package-details-new"}
                                isActiveBottomInfo={false}
                                dateInfoBottom={false}
                                textContentBottom={false}
                                SubscribeAction={() => {
                                  setShowModal(item);
                                  setActivePeriod("monthly");
                                  setActiveId(item.id);
                                  setPrice(item?.monthly);
                                }}
                                isActiveButtonSubscription={true}
                                isActiveButtonReload={false}
                                isActiveRibbon={false}
                                ribbonText={"نرشحها لك"}
                                isActiveProgress={undefined}
                                textProgressDate={"مشترك منذ 12/12/2023"}
                                textManyDay={"متبقي 8 أيام على التجديد"}
                                iconOne={undefined}
                                iconOne2={undefined}
                                dateInfoBottom2={undefined}
                                textContentBottom2={undefined}
                                iconOne3={undefined}
                                dateInfoBottom3={undefined}
                                textContentBottom3={undefined}
                                show_in_home={item.show_in_home}
                                show_first={item.show_first}
                                projects_apply={
                                  item.projects_apply >= 1000
                                    ? t("unlimited")
                                    : item.projects_apply
                                }
                                work_count={item.work_count}
                                offers_first={item.offers_first}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <NoResults text={t("noSubsicretopins")} />
                    )}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="ending"
                title={`${t("6 months")} - 15% ${t("off")}`}
              >
                <div className="all-subscriptions">
                  <div className="row g-3">
                    {data?.length > 0 ? (
                      <div className="row">
                        {data?.map((item, index) => {
                          if (item?.six_month == 0) {
                            return;
                          }
                          return (
                            <div
                              key={index}
                              className={`${
                                data.length == 1
                                  ? "col-12"
                                  : data.length == 2
                                  ? "col-lg-6 col-md-12"
                                  : "col-lg-4 col-md-6 col-12"
                              }`}
                            >
                              <PackagesCard
                                imgIcon={imgIcon}
                                titlePackage={
                                  i18n.language === "en"
                                    ? item.title_tr.en
                                    : item.title_tr.ar
                                }
                                textPackage={item?.description}
                                numPricing={item?.six_month}
                                dateType={data[0]?.duration}
                                moneyType={t("AED")}
                                iconTypeCheck={faCheck}
                                textIconPricing={"إظهار بالبداية عند البحث"}
                                textButton={t("subscribe now")}
                                newClass={"package-details-new"}
                                isActiveBottomInfo={false}
                                dateInfoBottom={false}
                                textContentBottom={false}
                                SubscribeAction={() => {
                                  setShowModal(item);
                                  setActivePeriod("six_month");
                                  setActiveId(item.id);

                                  setPrice(item?.six_month);
                                }}
                                isActiveButtonSubscription={true}
                                isActiveButtonReload={false}
                                isActiveRibbon={false}
                                ribbonText={"نرشحها لك"}
                                isActiveProgress={undefined}
                                textProgressDate={"مشترك منذ 12/12/2023"}
                                textManyDay={"متبقي 8 أيام على التجديد"}
                                iconOne={undefined}
                                iconOne2={undefined}
                                dateInfoBottom2={undefined}
                                textContentBottom2={undefined}
                                iconOne3={undefined}
                                dateInfoBottom3={undefined}
                                textContentBottom3={undefined}
                                show_in_home={item.show_in_home}
                                show_first={item.show_first}
                                projects_apply={
                                  item.projects_apply > 1000
                                    ? t("unlimited")
                                    : item.projects_apply
                                }
                                work_count={item.work_count}
                                offers_first={item.offers_first}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <NoResults text={t("noSubsicretopins")} />
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Year" title={`${t("Yearly")} - 30% ${t("off")}`}>
                <div className="all-subscriptions">
                  <div className="row g-3">
                    {data?.length > 0 ? (
                      <div className="row">
                        {data?.map((item, index) => {
                          if (item?.yearly == 0) {
                            return;
                          }
                          return (
                            <div
                              key={index}
                              className={`${
                                data.length == 1
                                  ? "col-12"
                                  : data.length == 2
                                  ? "col-lg-6 col-md-12"
                                  : "col-lg-4 col-md-6 col-12"
                              }`}
                            >
                              <PackagesCard
                                imgIcon={imgIcon}
                                titlePackage={
                                  i18n.language === "en"
                                    ? item.title_tr.en
                                    : item.title_tr.ar
                                }
                                textPackage={item?.description}
                                numPricing={item?.yearly}
                                dateType={data[0]?.duration}
                                moneyType={t("AED")}
                                iconTypeCheck={faCheck}
                                textIconPricing={"إظهار بالبداية عند البحث"}
                                textButton={t("subscribe now")}
                                newClass={"package-details-new"}
                                isActiveBottomInfo={false}
                                dateInfoBottom={false}
                                textContentBottom={false}
                                isActiveButtonSubscription={true}
                                SubscribeAction={() => {
                                  setShowModal(item);
                                  setActivePeriod("yearly");
                                  setActiveId(item.id);

                                  setPrice(item?.yearly);
                                }}
                                isActiveButtonReload={false}
                                isActiveRibbon={false}
                                ribbonText={"نرشحها لك"}
                                isActiveProgress={undefined}
                                textProgressDate={"مشترك منذ 12/12/2023"}
                                textManyDay={"متبقي 8 أيام على التجديد"}
                                iconOne={undefined}
                                iconOne2={undefined}
                                dateInfoBottom2={undefined}
                                textContentBottom2={undefined}
                                iconOne3={undefined}
                                dateInfoBottom3={undefined}
                                textContentBottom3={undefined}
                                show_in_home={item.show_in_home}
                                show_first={item.show_first}
                                projects_apply={
                                  item.projects_apply > 1000
                                    ? t("unlimited")
                                    : item.projects_apply
                                }
                                work_count={item.work_count}
                                offers_first={item.offers_first}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <NoResults text={t("noSubsicretopins")} />
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagesCards;
