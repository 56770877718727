import HeaderPages from "Components/HeaderPages/HeaderPages";
import "./Projects.css";
import imgLocation from "../../assets/images/icons/location.svg";
import iconUpload from "../../assets/images/icons/upload.svg";
import iconReview from "../../assets/images/icons/iconReview.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import DownloadButton from "Components/Button/DownloadBtn";
import NoResults from "Components/NotFound/noResults";
import { Link } from "react-router-dom";

const ProjectManagementRequestsDetails = () => {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    axiosInstance
      .get("/projectrequest", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      });
  }, []);
  return (
    <>
      <HelmetInfo titlePage={t("projectManagementRequests")} />

      <div className="all-mange">
        <HeaderPages
          titlePage={t("projectManagementRequests")}
          routePage={"/myProjects"}
          mainTitlePage={t("myProjects")}
          showMainTitlePage={true}
        />
        <div className="project-request-details card-style">
          <h2 className="title">{t("currentRequests")}</h2>
          {data.length > 0 ? (
            data?.map((item, index) => {
              return (
                <div className="info-request-card" key={index}>
                  {/* <div className="badge-info">{t("create")}</div> */}
                  <h2 className="title">{item?.title} </h2>
                  <div className="location d-flex align-items-center gap-1 ">
                    <img src={imgLocation} alt="icon Location" />
                    {item?.city} ,{item?.region}
                  </div>
                  <p className="text">{item?.content}</p>

                  <div>
                    {" "}
                    <DownloadButton fileUrl={item?.files[0]} />
                  </div>
                  {item?.status == 0 ? (
                    <div className="main-title-info d-flex align-items-center gap-2">
                      <h2 className="title">
                        {" "}
                        {t("yourProjectisUnderReview")}{" "}
                      </h2>
                    </div>
                  ) : item?.status == 1 ? (
                    <div className="main-title-info d-flex align-items-center gap-2">
                      <h2 className="title" style={{ color: "grren" }}>
                        {t("yourProjectisApproved")}
                      </h2>
                    </div>
                  ) : (
                    <div className="main-title-info d-flex align-items-center gap-2">
                      <h2 className="title" style={{ color: "red" }}>
                        {t("youRequestisRejected")}
                      </h2>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <NoResults text={t("noProjectRequests")}>
              <Link to={"/projectManagementRequest"} className="btn-main">
                {t("createProjectRequest")}
              </Link>
            </NoResults>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectManagementRequestsDetails;
