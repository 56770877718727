import CardTab from "Components/CardTab/CardTab";
import HeaderPages from "Components/HeaderPages/HeaderPages";
import SideBarFilter from "Components/SideBarFilter/SideBarFilter";
import img from "../../assets/images/main/image1.png";
import PaginationPage from "Components/Pagination/Pagination";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import CardSkelaton from "Components/Skelaton/CardSkelaton";
import { useTranslation } from "react-i18next";
import { set } from "rsuite/esm/utils/dateUtils";
import NoResults from "Components/NotFound/noResults";
const AllExperts = () => {
  const allProjects = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ];
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [badgeView, setBadgeView] = useState(6);

  const [typefilter, setTypefilter] = useState([
    "providers",
    "advisors",
    "contractors",
  ]);
  const [mergedAndShuffledArray, setMergedAndShuffledArray] = useState([]);
  const [showenResult, setShowenResult] = useState([]);
  const { t, i18n } = useTranslation();
  const [timeFilter, setTimeFilter] = useState();
  const [SelectedCityes, setSelectedCityes] = useState();
  const [showProviders, setShowProviders] = useState(false);
  const [SelectedCategoryLebal, setSelectedCategorylebal] = useState();

  const [SelectedCategory, setSelectedCategory] = useState();
  useEffect(() => {
    axiosInstance
      .get("/providers", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setProviders(res.data.providers);
        setAdvisor(res.data.advisors);
        setContractor(res.data.contractors);
      });
  }, [i18n.language]);

  // useEffect(() => {
  //   // Merge the arrays
  //   let mergedArray = [...providers, ...advisor, ...contractor];

  //   if (
  //     typefilter.includes("providers") &&
  //     typefilter.includes("advisors") &&
  //     typefilter.includes("contractors")
  //   ) {
  //     mergedArray = [...providers, ...advisor, ...contractor];
  //   } else if (
  //     typefilter.includes("providers") &&
  //     typefilter.includes("advisors")
  //   ) {
  //     mergedArray = [...providers, ...advisor];
  //   } else if (
  //     typefilter.includes("providers") &&
  //     typefilter.includes("contractors")
  //   ) {
  //     mergedArray = [...providers, ...contractor];
  //   } else if (
  //     typefilter.includes("advisors") &&
  //     typefilter.includes("contractors")
  //   ) {
  //     mergedArray = [...advisor, ...contractor];
  //   } else if (typefilter.includes("providers")) {
  //     mergedArray = [...providers];
  //   } else if (typefilter.includes("advisors")) {
  //     mergedArray = [...advisor];
  //   } else if (typefilter.includes("contractors")) {
  //     mergedArray = [...contractor];
  //   }

  //   // Shuffle the merged array using Fisher-Yates shuffle algorithm
  //   for (let i = mergedArray.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [mergedArray[i], mergedArray[j]] = [mergedArray[j], mergedArray[i]];
  //   }
  //   if (SelectedCityes) {
  //     const filterdData = mergedArray.filter(
  //       (item) => item.city_id == SelectedCityes.value
  //     );
  //     mergedArray = filterdData;
  //   }
  //   if (SelectedCategory) {
  //     const filterdData = mergedArray.filter(
  //       (item) => item.category_id == SelectedCategory.value
  //     );
  //     mergedArray = filterdData;
  //   }
  //   if (timeFilter) {
  //     // if(lastDay=="lastDay")
  //     // {
  //     //   const filterdData = mergedArray.filter(
  //     //     (item) => item.last_day
  //     //   );
  //     //   mergedArray = filterdData;
  //     // }
  //   }

  //   // Set the merged and shuffled array to state
  //   setMergedAndShuffledArray(mergedArray);
  //   setShowenResult(mergedArray);
  // }, [
  //   typefilter,
  //   advisor,
  //   contractor,
  //   providers,
  //   SelectedCityes,
  //   timeFilter,
  //   SelectedCategory,
  // ]);

  //++++++++++++++++++++++++++++ show provider filter ++++++++++++++++++++++++++++++++
  useEffect(() => {
    // Merge the arrays
    let mergedArray = [...providers, ...advisor, ...contractor];

    if (
      typefilter.includes("providers") &&
      typefilter.includes("advisors") &&
      typefilter.includes("contractors")
    ) {
      mergedArray = [...providers, ...advisor, ...contractor];
    } else if (
      typefilter.includes("providers") &&
      typefilter.includes("advisors")
    ) {
      mergedArray = [...providers, ...advisor];
    } else if (
      typefilter.includes("providers") &&
      typefilter.includes("contractors")
    ) {
      mergedArray = [...providers, ...contractor];
    } else if (
      typefilter.includes("advisors") &&
      typefilter.includes("contractors")
    ) {
      mergedArray = [...advisor, ...contractor];
    } else if (typefilter.includes("providers")) {
      mergedArray = [...providers];
    } else if (typefilter.includes("advisors")) {
      mergedArray = [...advisor];
    } else if (typefilter.includes("contractors")) {
      mergedArray = [...contractor];
    }

    // Sort the array by 'show_in_home' key (1 first, 0 second)
    mergedArray.sort((a, b) => b.show_in_home - a.show_in_home);

    if (SelectedCityes) {
      const filterdData = mergedArray.filter(
        (item) => item.city_id == SelectedCityes.value
      );
      mergedArray = filterdData;
    }
    if (SelectedCategory) {
      console.log(SelectedCategory);
      const filteredData = mergedArray.filter((item) =>
        item.categories.some((category) => category.id === SelectedCategory)
      );
      mergedArray = filteredData;
    }
    if (timeFilter) {
      // Handle timeFilter logic here
    }

    // Set the merged and sorted array to state
    setMergedAndShuffledArray(mergedArray);
    setShowenResult(mergedArray);
  }, [
    typefilter,
    advisor,
    contractor,
    providers,
    SelectedCityes,
    timeFilter,
    SelectedCategory,
  ]);

  useEffect(() => {
    if (typefilter.includes("providers")) {
      setShowProviders(true);
    } else {
      setShowProviders(false);
    }
  }, [typefilter]);

  useEffect(() => {}, [SelectedCityes]);
  function searchContractorsByName(name) {
    // Convert the search query to lowercase for case-insensitive search
    const query = name.toLowerCase();

    // Filter the contractors array based on the name
    const searchResults = mergedAndShuffledArray?.filter(
      (contractor) =>
        contractor?.company_name_en?.toLowerCase().includes(query) ||
        contractor?.company_name_ar?.toLowerCase().includes(query) ||
        contractor?.id?.toString().includes(query) ||
        contractor?.name?.toLowerCase().includes(query)
    );
    setShowenResult(searchResults);
  }

  return (
    <>
      <HelmetInfo titlePage={t("allExpertsTitle")} />
      <div className="all-projects-page">
        <HeaderPages
          titlePage={t("allExpertsTitle")}
          routePage={false}
          mainTitlePage={false}
          showMainTitlePage={false}
        />
        {/* =========== START MAIN PROJECTS PAGE ============ */}
        <div className="main-projects-page">
          {/* ========== START ROW ======== */}
          <div className="row g-3">
            {/* ========= START COL ========= */}
            <div className="col-12  col-md-4">
              <SideBarFilter
                setTypefilter={setTypefilter}
                typefilter={typefilter}
                showProviders={showProviders}
                isActive={true}
                searchContractorsByName={searchContractorsByName}
                setSelectedCityes={setSelectedCityes}
                setTimeFilter={setTimeFilter}
                setSelectedCategory={setSelectedCategory}
                setSelectedCategoryLebal={setSelectedCategorylebal}
              />
            </div>

            {/* ========= END COL ========= */}
            {/* ========= START COL ========= */}
            <div className="col-12   col-md-8">
              <div className="all-projects-content tabs-content-cards">
                <div className="row g-3">
                  {loading
                    ? [1, 2, 3, 4, 5].map((e, index) => {
                        return <CardSkelaton key={index} />;
                      })
                    : showenResult.length
                    ? showenResult
                        ?.slice(badgeView - 6, badgeView)
                        .map((item) => {
                          const Rate = Math.floor(item?.rate * 10) / 10;

                          return (
                            <div className="col-12" key={item.id}>
                              {/* ======= START CARD ONE TAB ======== */}
                              <CardTab
                                productId={item.id}
                                imgCard={item.image}
                                titleCard={
                                  (i18n.language == "en"
                                    ? item.company_name_en
                                    : item.company_name_ar) || item.name
                                }
                                numRate={Rate.toFixed(1)}
                                textInfoBottom={"النشاط"}
                                titleInfoBottom={"بناء"}
                                textInfoBottom2={t("emirateLabel")}
                                titleInfoBottom2={item.city}
                                textInfoBottom3={t("areaLabel")}
                                titleInfoBottom3={item.region}
                                routeCard={`/theExpert/${item.id}`}
                                is_favorite={item.is_favorite}
                                jobType={item.type}
                                typeofsupply={item.category}
                                allData={item}
                              />
                              {/* ======= END CARD ONE TAB ======== */}
                            </div>
                          );
                        })
                    : null}
                </div>
                {showenResult?.length > 0 ? (
                  <div className="pagination-info">
                    <PaginationPage
                      itemperPage={6}
                      setBadgeView={setBadgeView}
                      itmeCount={showenResult?.length / 6}
                    />
                  </div>
                ) : (
                  <NoResults />
                )}
              </div>
            </div>
            {/* ========= END COL ========= */}
          </div>
          {/* ========== END ROW ======== */}
        </div>
        {/* =========== END MAIN PROJECTS PAGE ============ */}
      </div>
    </>
  );
};

export default AllExperts;
