import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import verified from "../../assets/images/icons/verified.png";
const CardTab = ({
  routeCard,
  imgCard,
  titleCard,
  numRate,
  textInfoBottom,
  titleInfoBottom,
  textInfoBottom2,
  titleInfoBottom2,
  textInfoBottom3,
  titleInfoBottom3,
  productId,
  is_favorite,
  jobType,
  allData,
  typeofsupply,
}) => {
  const [fillBg, setFillColor] = useState("#B0B0B0"); // Initial fill color
  const [borderColorIcon, setBorderColorIcon] = useState(false);
  const { t, i18n } = useTranslation();
  const changeIconBg = (e) => {
    e.preventDefault();

    axiosInstance
      .post(
        "/favourite",
        { type_id: productId, type: "customer" },
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      )
      .then(() => {
        toast.success(
          is_favorite
            ? t("toast.removedFromFavourites")
            : t("toast.addedToFavourites")
        );
        const newColor = fillBg === "#B0B0B0" ? "#fff" : "#B0B0B0";
        setFillColor(newColor); // CHANGE FILL COLOR SVG
        setBorderColorIcon(!borderColorIcon);
      });
    // CHANGE BORDER COLOR ICON
  };

  useEffect(() => {
    if (is_favorite) {
      setFillColor("#fff");
      setBorderColorIcon(true);
    }
  }, [is_favorite]);

  return (
    <>
      {/* ======= START CARD ONE TAB ======== */}
      <div
        className="card-one-tab"
        style={
          allData?.show_in_home === 1
            ? { border: "2px solid #eda600", backgroundColor: "#fff8e8" }
            : null
        }
      >
        <Link to={routeCard} state={{ id: productId }}>
          <div className="head-card d-flex justify-content-between align-items-center gap-2">
            <div className="info-right d-flex align-items-center gap-3">
              <div className="img">
                <img src={imgCard} alt="img" />
              </div>
              <div className="info-text">
                <h2 className="title">
                  {titleCard}
                  {allData?.is_verified === 1 && (
                    <img src={verified} alt="" className="p-1" />
                  )}
                </h2>
                <div className="d-flex mb-2 joptitleparent">
                  <p className="joptitle">{t("job")} : </p>
                  <p className="joptitleT">{t(`${jobType}`)}</p>
                </div>

                {(() => {
                  if (jobType === "provider" && typeofsupply === undefined) {
                    return (
                      <div
                        className="d-flex mb-2"
                        style={{ color: "#565656", fontWeight: "500" }}
                      >
                        <p>{t("typeofsupply")} : </p>
                        <p className="ms-1 me-1">{typeofsupply}</p>
                      </div>
                    );
                  }
                })()}
                <div className="icon-star d-flex  align-items-center  gap-2">
                  <FontAwesomeIcon icon={faStar} />
                  <span className="num-rate">{numRate}</span>
                </div>
              </div>
            </div>
            <div
              className={`icon-svg-tab ${borderColorIcon ? "active" : ""}`}
              onClick={changeIconBg}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M15 11.9004H10C9.59 11.9004 9.25 11.5604 9.25 11.1504C9.25 10.7404 9.59 10.4004 10 10.4004H15C15.41 10.4004 15.75 10.7404 15.75 11.1504C15.75 11.5604 15.41 11.9004 15 11.9004Z"
                  fill={fillBg}
                />
                <path
                  d="M12.5 14.46C12.09 14.46 11.75 14.12 11.75 13.71V8.70996C11.75 8.29996 12.09 7.95996 12.5 7.95996C12.91 7.95996 13.25 8.29996 13.25 8.70996V13.71C13.25 14.12 12.91 14.46 12.5 14.46Z"
                  fill={fillBg}
                />
                <path
                  d="M19.5703 23.25C19.0603 23.25 18.5003 23.1 17.9603 22.79L13.0803 20.08C12.7903 19.92 12.2203 19.92 11.9303 20.08L7.05031 22.79C6.06031 23.34 5.05031 23.4 4.28031 22.94C3.51031 22.49 3.07031 21.58 3.07031 20.45V6.36C3.07031 3.82 5.14031 1.75 7.68031 1.75H17.3303C19.8703 1.75 21.9403 3.82 21.9403 6.36V20.45C21.9403 21.58 21.5003 22.49 20.7303 22.94C20.3803 23.15 19.9803 23.25 19.5703 23.25ZM12.5003 18.46C12.9703 18.46 13.4303 18.56 13.8003 18.77L18.6803 21.48C19.1903 21.77 19.6603 21.83 19.9603 21.65C20.2603 21.47 20.4303 21.04 20.4303 20.45V6.36C20.4303 4.65 19.0303 3.25 17.3203 3.25H7.68031C5.97031 3.25 4.57031 4.65 4.57031 6.36V20.45C4.57031 21.04 4.74031 21.48 5.04031 21.65C5.34031 21.82 5.81031 21.77 6.32031 21.48L11.2003 18.77C11.5703 18.56 12.0303 18.46 12.5003 18.46Z"
                  fill={fillBg}
                />
              </svg>
            </div>
          </div>
          <div
            className={`bottom-info d-flex  justify-content-around  align-items-center  gap-2 
            ${allData?.show_in_home === 1 ? "goldenSpanSeprator" : null}
            `}
            style={
              allData?.show_in_home === 1
                ? { borderTop: "2px solid #eda600" }
                : null
            }
          >
            {/* <div className="info-bottom-one">
              <p className="text">{textInfoBottom}</p>
              <h2 className="title">{titleInfoBottom}</h2>
            </div> */}
            {/* <span className="line"></span> */}
            <div className="info-bottom-one">
              <p className="text">{textInfoBottom2}</p>
              <h2 className="title">{titleInfoBottom2}</h2>
            </div>
            <span className="line"></span>
            <div className="info-bottom-one">
              <p className="text">{textInfoBottom3}</p>
              <h2 className="title">{titleInfoBottom3}</h2>
            </div>
          </div>
        </Link>
      </div>
      {/* ======= END CARD ONE TAB ======== */}
    </>
  );
};

export default CardTab;
