import { useTranslation } from "react-i18next";
import uploadImage from "../../assets/images/icons/upload.svg";

const DownloadButton = ({ fileUrl, fileName }) => {
  const handleDownload = () => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "ملف المشروع";
    link.target = "_blank";

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Clean up by removing the link
    document.body.removeChild(link);
  };
  const { t } = useTranslation();

  return (
    <button className="file-upload-info file-up" onClick={handleDownload}>
      <div className="image-upload">
        <img src={uploadImage} alt="upload icon" loading="lazy" />
      </div>
      <div className="info-content">
        <h2 className="title">{t("fileUpload")}</h2>
      </div>
    </button>
  );
};
export default DownloadButton;
