import img1 from "../../../assets/LandingPageImages/main/01.jpeg";
import img2 from "../../../assets/LandingPageImages/main/02.jpeg";
import img3 from "../../../assets/LandingPageImages/main//03.jpeg";
import img4 from "../../../assets/LandingPageImages/main/04.jpeg";
import "./FeatureApp.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";

const FeatureApp = () => {
  const { t } = useTranslation();
  const cardsFeature = [
    {
      id: 0,
      dataAnimation: "fade-left",
      img: img1,
      title: t("Our Best Accredited Contractors"),
      text: [t("BuildIn app gives")],
    },
    {
      id: 1,
      dataAnimation: "fade-right",
      img: img2,
      title: t("Best Quality"),
      text: [
        t("Unleash new opportunities "),
        t("with BuildIn"),
        t("BuildIn provides the best quality services"),
      ],
    },
    {
      id: 2,
      dataAnimation: "fade-left",
      img: img3,
      title: t("24/7 Technical Support"),
      text: [
        t("BuildIn provides support around the clock"),
        t("secondClient"),
        t("thirdClient"),
      ],
    },
    {
      id: 3,
      dataAnimation: "fade-right",
      img: img4,

      title: t("Ease of Use"),
      text: [t("BuildIn provides a user-friendly and easy-to-use platform")],
    },
  ];
  return (
    <>
      {/* ============= START SECTION APP FEATURE ============= */}
      <section className="app-feature padding bg-section" id="app-feature">
        {/* ========= START CONTAINER =========== */}
        <div className="container">
          {/* ======== START SECTION TITLE INFO =========== */}
          <SectionTitle
            titleSection={t("")}
            text={t("servics and partners")}
            dataAnimationType={"fade-down"}
          />

          {/* ======== END SECTION TITLE INFO =========== */}
          {/* ========= START ALL APP FEATURE ========== */}
          <div className="all-app-feature">
            {/* ============= START ROW ============= */}
            <div className="row g-3">
              {cardsFeature.map((item, index) => {
                return (
                  <div className="col-12 col-md-6" key={index}>
                    {/* ========= START FEATURE ONE =========== */}
                    <div
                      className="feature-one h-100"
                      data-aos={item.dataAnimation}
                    >
                      {/* =========== START IMAGE FEATURE =========== */}
                      <div className="image-feature">
                        <img
                          src={item.img}
                          alt="img feature"
                          className="w-100 h-100 object-fit-cover"
                          loading="lazy"
                        />
                      </div>
                      {/* =========== END IMAGE FEATURE =========== */}
                      {/* =========== START ICON FEATURE ========== */}
                      <div className="icon-feature">
                        <div className="icon-svg"></div>
                      </div>
                      {/* =========== END ICON FEATURE ========== */}
                      {/* =========== START INFO FEATURE ========== */}
                      <div className="info-feature">
                        <h2 className="title">{item.title}</h2>
                        <p className="text">
                          {item?.text?.map((item, index) => {
                            return <p key={index}>{item}</p>;
                          })}
                        </p>
                      </div>
                      {/* =========== END INFO FEATURE ========== */}
                    </div>
                    {/* ========= END FEATURE ONE =========== */}
                  </div>
                );
              })}
            </div>
            {/* ============= END ROW ============= */}
          </div>
          {/* ========= END ALL APP FEATURE ========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </section>
      {/* ============= END SECTIOn APP FEATURE ============= */}
    </>
  );
};

export default FeatureApp;
