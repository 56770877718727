import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import MapPicker from "Components/mapPicker/mapPicker";
import axiosInstance from "../../../axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { usePlacesWidget } from "react-google-autocomplete";
import Location from "Components/mapPicker/officesMap";
import { toast } from "react-toastify";

const SetLocationTab = ({ data, handleCloseModalEditWork, refetch }) => {
  // FORM SUBMIT
  const { t } = useTranslation();

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBAkwVYHpFxiSiYG5JWJ2Pq_Bo-llnewTI",
    onPlaceSelected: (place) => console.log(place),
  });
  const [loading, setLoaidng] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    setLoaidng(true);
    let postData;
    if (selectedLocation?.lat && selectedLocation?.lng) {
      postData = {
        ...values,
        company_lat: selectedLocation.lat,
        company_lng: selectedLocation.lng,
      };
    } else {
      postData = {
        ...values,
      };
    }

    axiosInstance.post("/profile", postData).then((res) => setLoaidng(false));
    setTimeout(() => {
      refetch();
    }, 1000);
    toast.success(t("success"));
    resetForm({ values: "" });
    handleCloseModalEditWork();
  };
  const validationSchema = Yup.object().shape({
    company_address: Yup.string(),
    company_email: Yup.string().email(t("emailError")),
    company_phone: Yup.number(),
  });

  const initialValues = {
    company_address: data?.company_address || "",
    company_email: data?.company_email || "",
    company_phone: data?.company_phone || "",
  };
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  return (
    <>
      <div className="set-location">
        {/* ========= START FORM INFO  ========== */}
        <div className="form-info">
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="row g-3">
              {/* ========= START COL ======== */}
              <div className="col-12 col-md-12">
                <InputFiled
                  label={t("locationAddress")}
                  name="company_address"
                  type="search"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}

              {/* ========= START COL ======== */}
              <div className="col-12 col-md-12">
                {/* ========= START MAP LOCATION ======== */}
                <div className="form-check d-flex align-items-center gap-2">
                  <input
                    className="form-check-input"
                    onChange={() => {
                      setShowMap(!showMap);
                    }}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <h2 style={{ fontSize: "16px" }}>
                    {" "}
                    {t("Choose Locaiton on Map")}
                  </h2>
                </div>
                {showMap && (
                  <div className="map-location">
                    <h2 style={{ fontSize: "16px" }}>
                      {" "}
                      {t("pressonLocation")}
                    </h2>
                    {/* <MapPicker
              lat={data?.company_lat}
              lng={data?.company_lng}
              onLocationChange={handleLocationChange}
            /> */}
                    <Location
                      lat={data?.company_lat}
                      lng={data?.company_lng}
                      onLocationChange={handleLocationChange}
                    />
                  </div>
                )}
                {/* ========= END MAP LOCATION ======== */}
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12 col-md-6">
                <InputFiled
                  label={t("emailLabel")}
                  name="company_email"
                  type="email"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}

              {/* ========= START COL ======== */}
              <div className="col-12 col-md-6">
                <InputFiled
                  label={t("phoneLabel")}
                  name="company_phone"
                  type="number"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}
            </div>

            <button disabled={loading} type="submit" className="btn-main w-100">
              {loading ? <span className="loader"></span> : t("saveButton")}
            </button>
          </FormField>
        </div>
        {/* ========= END FORM INFO ========== */}
      </div>
    </>
  );
};

export default SetLocationTab;
