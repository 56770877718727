import HeaderPages from "Components/HeaderPages/HeaderPages";
import InfoOwnerDetails from "Pages/ProductDetailsPage/InfoOwnerDetails";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import CardProductDetails from "Pages/ProductDetailsPage/CardProductDetails";
import { Accordion } from "react-bootstrap";
import image from "../../assets/images/main/image1.png";
import iconCurrancy from "../../assets/images/icons/dollar-circle.svg";
import CardOffer from "Pages/Home/OffersPackages/CardOffer";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import DownloadButton from "Components/Button/DownloadBtn";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const ProjectOneDetails = () => {
  const detailsCard = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const location = useLocation();
  const [offers, setoffers] = useState([]);
  const [showOwner, setShowOwner] = useState(false);
  const [showProvider, setShowProvider] = useState();
  const userId = useSelector((state) => state.auth.userId);

  const refetch = () => {
    axiosInstance
      .get(`/project/${params.id}`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setloading(false);
        setoffers(res.data.data.offers);
      });
  };
  useEffect(() => {
    if (location.state?.showOwner) {
      setShowOwner(true);
    }

    axiosInstance
      .get(`/project/${params.id}`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setloading(false);
        setoffers(res.data.data.offers);
        console.log(res.data.data.offers);
      });
  }, []);

  useEffect(() => {
    if (location.state?.showOwner) {
    } else {
      if (data?.customer?.id == userId) {
        setShowProvider(data?.provider);
      }
    }
  }, [data]);
  return (
    <>
      <HelmetInfo titlePage={"مشروع"} />

      <HeaderPages
        titlePage={t("Project")}
        routePage={"/myProjects"}
        mainTitlePage={t("myProjects")}
        showMainTitlePage={true}
      />
      <div className="products-details-page">
        {/* ======== START ALL CARD PRODUCTS DETAILS ========= */}
        <div className="all-card-product-details">
          <CardProductDetails
            noNavigation={true}
            projectId={data?.id}
            img={data?.images || img}
            altImg={"img Card"}
            badgeText={data?.type}
            titleCard={data?.title}
            titleAddress={`${data?.region} ,${data?.city}`}
            iconDollar={iconDollar}
            priceCard={data?.price}
            typePrice={t("AED")}
            textPrice={t("Expected value")}
            iconCalendar={iconCalendar}
            dateCard={data?.end_date}
            textInfoDate={"تاريخ التسليم المتوقع"}
            numWatch={data?.views}
            textWatch={t("watch")}
            numOffer={data?.offers_count}
            textOffer={t("offers")}
            status={data?.status}
            numDay={data?.since}
            isActiveMainButtons={false}
            isActiveButtonUpdateAndStop={!showOwner && data?.provider}
            isActiveFinalCard={false}
            dateCard2={data?.start_date}
            textInfoDate2={t("Project start date")}
            dateCard3={data?.accept_date}
            textInfoDate3={t("Date of acceptance of offers")}
            isActiveDate={true}
            isActiveOffersWatch={true}
            routeCard={false}
            refetch={refetch}
            hideofferBtn={true}
            showRateBtn={data?.status == 4}
            hideRateBtn={!data?.rate}
            refetch={refetch}
          />
        </div>
        {/* ======== END ALL CARD PRODUCTS DETAILS ========= */}
        <InfoOwnerDetails
          setchatData={data?.customer}
          showChatBtn={showOwner}
          data={data}
          showProvider={showProvider}
        />
      </div>
      <div className="project-details-info-pro">
        <h2 className="title">
          {data?.provider
            ? "العروض المقبولة"
            : ` ${t("offers")}  (${data?.offers_count}) `}
        </h2>
        <div className="all-main-cards d-flex flex-column  gap-2">
          <div className="row g-3">
            {offers?.map((item, index) => {
              if (item.status == 1) {
                return (
                  <div className="col-12 col-lg-6" key={index}>
                    {/* ====== START MAIN CARD PROJECT ====== */}
                    <div className="main-card-project-1">
                      {/* ======== START CARD DETAILS INFO ========== */}
                      <CardOffer
                        numDay={"3"}
                        expertId={item.provider.id}
                        image={item.provider.image}
                        imageCardAlt={"image-card"}
                        titleCard={item.provider.company_name_ar}
                        textCard={"مبنى سكني مكون من 10 أدوار"}
                        rateNum={null}
                        textCardTrue={false}
                        cardTwoInfo={true}
                        iconCurrancy={iconCurrancy}
                        iconAlt={"iconCurrancy"}
                        priceNum={item.price}
                        typePrice={"درهم"}
                        textPrice={"السعر"}
                        textButtonOne={"قبول"}
                        textButtonTwo={"تواصل"}
                        showBtns={false}
                        buttonTwoActive={false}
                        place={`${item?.provider.region}`}
                        buttonThreeActive={true}
                        offerId={item.id}
                      />
                      {/* ======== END CARD DETAILS INFO ========== */}
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>التفاصيل</Accordion.Header>
                          <Accordion.Body>
                            <h2 className="title">تعليق</h2>
                            <p className="text">{item.content}</p>

                            <DownloadButton fileUrl={item?.images[0]} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    {/* ====== END MAIN CARD PROJECT ====== */}
                  </div>
                );
              }
              if (!data.provider) {
                return (
                  <div className="col-12 col-lg-6" key={item.id}>
                    {/* ====== START MAIN CARD PROJECT ====== */}
                    <div className="main-card-project-1">
                      {/* ======== START CARD DETAILS INFO ========== */}
                      <CardOffer
                        // here homie
                        // numDay={"3"}
                        expertId={item.provider.id}
                        image={item.provider.image}
                        imageCardAlt={"image-card"}
                        titleCard={
                          i18n.language === "en"
                            ? item.provider.company_name_en
                            : item.provider.company_name_ar
                        }
                        textCard={"مبنى سكني مكون من 10 أدوار"}
                        rateNum={item.provider.rate}
                        textCardTrue={false}
                        cardTwoInfo={true}
                        iconCurrancy={iconCurrancy}
                        iconAlt={"iconCurrancy"}
                        priceNum={item.price}
                        typePrice={t("AED")}
                        textPrice={t("price")}
                        textButtonOne={t("accept")}
                        textButtonTwo={t("Contact")}
                        showBtns={true}
                        buttonTwoActive={false}
                        buttonThreeActive={true}
                        offerId={item.id}
                        place={`${item?.provider.city} ,${item?.provider.region}`}
                        ChatProfile={{ ...item.provider, newChat: true }}
                        refetch={refetch}
                      />
                      {/* ======== END CARD DETAILS INFO ========== */}
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>{t("Details")}</Accordion.Header>
                          <Accordion.Body>
                            <h2 className="title">{t("Comment")}</h2>
                            <p className="text">{item.content}</p>

                            <DownloadButton fileUrl={item?.images[0]} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    {/* ====== END MAIN CARD PROJECT ====== */}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectOneDetails;
